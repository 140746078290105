<div class="container px-5">
    <!-- <h5 class="alert-otp">An OTP has been sent to your Gmail.</h5> -->
    <!-- <div  *ngIf="isAlertVisible" class="alert alert-info custom-alert" role="alert"> -->
        <div *ngIf="isAlertVisible" [ngClass]="['custom-alert', 'alert', 'alert-info', {'fade-out': isAlertFading}]" role="alert">
            An OTP has been sent to your email.
        </div>
    <h2 class="pt-5">Verify OTP</h2>
    <h6>Please enter OTP received in your email</h6>
    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" style="padding-top: 4rem;">
        <div class="form-group mb-1">
            <label>OTP</label>
            <input type="text" autocomplete="off" formControlName="otp" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('otp')?.errors}" class="form-control" placeholder="Enter your email OTP">
        </div>
        <div class="text-right">
            <!-- <span class="alert in success-green" [hidden]="success_msg">
                {{success_msg}}
            </span> -->
            <span class="alert in error-red">
                {{error_msg}}
            </span>
        </div>
        <button [disabled]="!(success_msg===null && error_msg===null)" type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem; width: auto;">
            Confirm OTP
        </button>
    </form>
</div>