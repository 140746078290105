<!-- section1 - innovations -->
<!-- <div *ngIf="loading" class="loader-overlay">
  <img src="../../assets/image/axldrone-loading.png">
</div> -->

<section

  class="banner-innovation"
  [class.banner-innovation-video-overlay]="firstPlay"
>


  <!-- <div style="margin-top: 10px;">
   
</div> -->
  <!-- <div *ngIf="loading" class="loader-overlay">
  <div class="spinner-border spinner-border-lg" role="status">
    
  </div>
</div>
<app-popover-component
[showModal]="showModal"
[loading]="loading"
(closeModal)="closeModal()"
(setLoading)="setLoading($event)"
(setShowModal)="setShowModal($event)"
></app-popover-component> -->
  <div
    *ngIf="newsFeeds !== null && newsFeeds !== undefined && newsFeeds !== ''"
    class="marquee"
  >
    <span style="font-size: 17px" *ngFor="let i of [1, 2, 3, 4, 5]">
      {{ newsFeeds }}
    </span>
  </div>
 
  <div class="container banner-innovation-content">
    <app-carousel [images]="carouselImages"></app-carousel>
    <!-- <section class="banner-innovation banner-innovation-video-overlay"> -->
    <!-- <div class="overlay"></div> -->
    <div class="banner-innovation-video container-fluid p-0 mx-auto">
      
      <!-- <div class="embed-responsive d-lg-none">
            <video class="embed-responsive-item" [src]="mobileVideo" autoplay loop [muted]="'muted'" poster="../../assets/image/home_section1.png" type="video/mp4">
                Your browser does not support HTML5 video.
            </video>
        </div>
        <div class="embed-responsive d-none d-lg-block"> -->
      <div class="embed-responsive">
       
        <!-- <video class="embed-responsive-item" [src] = "environment.assetUrl+'/'+innovations[0].homepage_video_file" autoplay [muted]="'muted'" loop > -->
        <!-- <video #videoPlayer style="width: 100%;" class="embed-responsive-item" src= "../../assets/video/homepage.mp4" autoplay [muted]="'muted'" loop > -->
        <video
          #videoPlayer1
          [hidden]="!player1Playing && !firstPlay"
          class="embed-responsive-item video-responsive"
          style="position: absolute; z-index: -2"
          [src]="player1Src"
          [muted]="'muted'"
          poster="../../assets/image/home_video_poster.jpg"
          type="video/mp4"
        >
          Your browser does not support HTML5 video.
        </video>
        <video
          #videoPlayer2
          [hidden]="!player2Playing"
          class="embed-responsive-item video-responsive"
          style="position: absolute; z-index: -3"
          [src]="player2Src"
          [muted]="'muted'"
          type="video/mp4"
        >
          Your browser does not support HTML5 video.
        </video>
        <video
          #videoPlayer3
          [hidden]="!player3Playing"
          class="embed-responsive-item video-responsive"
          style="position: absolute; z-index: -4"
          [src]="player3Src"
          [muted]="'muted'"
          type="video/mp4"
        >
          Your browser does not support HTML5 video.
        </video>
        <video
          #videoPlayer4
          [hidden]="!player4Playing"
          class="embed-responsive-item video-responsive"
          style="position: absolute; z-index: -5"
          [src]="player4Src"
          [muted]="'muted'"
          type="video/mp4"
        >
          Your browser does not support HTML5 video.
        </video>

        <!-- <div *ngFor="let playSrc of playlistSrc">
                <input #boofoo type="text" (click)="onClick($event, name)" />
                <button (click)="boofoo.click()">Change text</button>
            </div> -->
      </div>
    </div>

    <!-- <div>Shot on xxxx</div> -->
    <div class="row py-5" style="margin: 0px 2vh;">
      <!-- left text -->
      <div class="col-lg-8 mb-5 mt-5">
        <h1>
          Autonomous Drone Solutions <br />
          For Your Business
        </h1>
        <!-- <p>VideoPlayer1 loaded: {{player1Loaded}}</p>
                <p>VideoPlayer1 started: {{player1Playing}}</p>
                <p>VideoPlayer2 loaded: {{player2Loaded}}</p>
                <p>VideoPlayer2 started: {{player2Playing}}</p>
                <p>VideoPlayer3 loaded: {{player3Loaded}}</p>
                <p>VideoPlayer3 started: {{player3Playing}}</p>
                <p>VideoPlayer4 loaded: {{player4Loaded}}</p>
                <p>VideoPlayer4 started: {{player4Playing}}</p> -->
        <button
          [routerLink]="['/lets-talk']"
          type="button"
          class="btn btn-secondary button-custom"
        >
          Meet with an Expert
        </button>
      </div>

      <!-- right carousel  -->
      <div class="col-lg-4 mt-3 d-none d-lg-block">
        <div class="ml-5 mr-5 vstack gap-3">
          <button
            
      
            *ngFor="let innovation of innovations"
            [routerLink]="['/innovation', innovation.slug]"
            type="button"
            class="btn btn-secondary button-custom1 home-innovations"
          >
            {{ innovation.title }}
            <span style="float: right">
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16 31V31C7.715 31 1 24.285 1 16V16C1 7.715 7.715 1 16 1V1C24.285 1 31 7.715 31 16V16C31 24.285 24.285 31 16 31Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.6663 15.9998H9.33301"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.667 11L22.667 16L17.667 21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

</section>

<!-- section2 - products -->
<section
  class="gradientback"
  style="padding-top: 3rem; padding-bottom: 3em; background-color: #6f6e73"
>
  <div class="container">
    <div
      class="row justify-content-center text-center"
      style="padding-bottom: 1rem"
    >
      <h2 style="color: #ffffff">Our Featured Products</h2>
    </div>
    <div class="row justify-content-center">
      <div class="col-6 col-lg-3" *ngFor="let storeItem of storeItems">
        <a
          [routerLink]="['/product', storeItem.slug]"
          style="text-decoration: none"
        >
          <div class="card card-product" style="border: 0">
            <div>
              <img
                class="card-img-top"
                src="{{ environment.assetUrl }}/{{ storeItem.image }}"
                alt="Card image cap"
                style="border-radius: 1rem; border-color: #f97738"
              />
            </div>
            <div class="card-body text-center" style="color: #ffffff">
              <h5 class="card-title">{{ storeItem.title }}</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- section3 - accreditations -->
<section
  style="padding-top: 3rem; padding-bottom: 2rem; background-color: #1e1d24"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-6 col-sm-4 col-lg-2 mb-4 p-0">
        <div class="card card-accreditations">
          <img
            style="pointer-events: none"
            class="card-img"
            src="../../../assets/image/accreditations-logo/00_kerala_startup_mission.png"
          />
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mb-4 p-0">
        <div class="card card-accreditations">
          <img
            style="pointer-events: none"
            class="card-img"
            src="../../../assets/image/accreditations-logo/01_gem.png"
          />
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mb-4 p-0">
        <div class="card card-accreditations">
          <img
            style="pointer-events: none"
            class="card-img"
            src="../../../assets/image/accreditations-logo/02_qci.png"
          />
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mb-4 p-0">
        <div class="card card-accreditations">
          <img
            style="pointer-events: none"
            class="card-img"
            src="../../../assets/image/accreditations-logo/03_msme.png"
          />
        </div>
      </div>
      <div class="col-6 col-sm-4 col-lg-2 mb-4 p-0">
        <div class="card card-accreditations">
          <img
            style="pointer-events: none"
            class="card-img"
            src="../../../assets/image/accreditations-logo/04_startupindia.png"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- section4 - drone solution -->
<section class="banner-solution" style="position: relative">
  <div
    style="
      position: absolute;
      top: -2.5rem;
      left: 50%;
      transform: translate(-50%, 0%);
    "
  >
    <div>
      <!-- <div class="row logos rounded p-2" style="background-color: white; ">
                <!- - portrait - ->
                <div class="col-lg-auto d-lg-none">
                    <h4 class="text-nowrap text-center" style="color: black;">Our Accreditations</h4>
                </div>
                <div class="col-lg-auto d-lg-none" style="width: 80vw;">
                    <app-pagesub-accreditations></app-pagesub-accreditations>
                </div>

                <!- - landscape - ->
                <div class="col-lg-auto d-none d-lg-block">
                    <div class="hstack gap-3">
                        <h4 class="text-nowrap text-center" style="color: black;">Our Accreditations :</h4>
                        <div style="width: 600px;">
                            <app-pagesub-accreditations ></app-pagesub-accreditations>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <div
    class="container text-center vstack gap-3 mt-3"
    style="text-align: justify !important"
  >
    <h2>
      Fully Integrated Autonomous Drone Solutions for Your Business, Farm,
      Rescue Missions, and Artistic Endeavors
    </h2>
    <p>
      Fully customized drones with superb control and flexibility maintain a
      safe environment in and around your premises. The Axl platform focuses on
      providing accurate and high-resolution data and in-depth analysis to
      support that vision, which made us Runners Up in the surveillance category
      of the 2021 Drone Hackathon organized by Kerala Police. Beyond the feature
      set, our drones, our platform, and our support system work together to
      address your business and other needs as one comprehensive product-service
      platform.
    </p>
  </div>
</section>
