// layout.component.ts
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  environment = environment;
  showModal = false; // Initialize modal to be hidden by default
  loading = true; // Initialize loader to be visible by default
  selectedImage: string = '';
  advimages: string[] = [];
  newsFeeds: string = '';

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.setLoading(true);
    this.loadAdvertisementList();
  }

  loadAdvertisementList(): void {
    this.dataService.advertisementList().subscribe((data: any) => {
      if (data.success) {
     
        this.advimages = data.ads_list;
        this.newsFeeds = data.newsfeed; // Fetch news feeds from API
        
        if (this.advimages.length > 0) {
          this.selectRandomImage(); // Select random image if ads_list has items
        } else {
          this.setLoading(false); // Hide loader if no images
          this.showModal = false; // Hide modal if no images
        }
      } else {
        this.setLoading(false); // Hide loader on error
        this.showModal = false; // Hide modal on error
      }
    }, error => {

      this.setLoading(false); // Hide loader on error
      this.showModal = false; // Hide modal on error
    });
  }

  selectRandomImage(): void {
    const randomIndex = Math.floor(Math.random() * this.advimages.length);
    this.selectedImage = this.advimages[randomIndex];
    this.setLoading(false); // Hide loader once the image is selected
    this.showModal = true; // Show modal once the image is selected
  }

  closeModal(): void {
    this.showModal = false; // Close modal logic
  }

  setLoading(loading: boolean): void {

    this.loading = loading;
  }
}
