<div class="privacy">
    <div class="row mx-0 h-100 justify-content-center align-items-center">
      <div class="col-md-12 col-lg-7 col-xl-6 text-center banner">
        <h1 data-aos="fade-up" data-aos-duration="1000">Shipping Policy</h1>
      </div>
    </div>
</div>
  <!--Banner End-->
  
  <!--Section Category Start-->
  <div class="privacy_body">
    <div class="container">

        <!-- <h4>Shipping Policy</h4> -->
      <p>Thank you for using our app! Please note that our app exclusively offers digital services and content. We do not involve the shipment of any physical goods or services. As a result, we do not collect or process any shipping-related information, such as physical addresses or delivery preferences.</p>
      <p><strong>Digital Services and Content Delivery:</strong>
      Our app provides digital services and content, which include but are not limited to access to online resources, software, media, and other digital products.</p>
      <p><strong>Data Collection and Privacy:</strong>
      We respect your privacy. Since we do not deal with physical shipments, we do not collect shipping-related data such as addresses or delivery instructions. The personal information we collect and process is limited to what is necessary for providing and improving our digital services, managing user accounts, and processing payments where applicable.</p>
      <p><strong>Compliance:</strong>
      We are committed to complying with applicable data protection regulations concerning the collection, processing, and storage of personal information. For more information on how we handle your data, please refer to our Privacy Policy.</p>
      <p><strong>Contact Us:</strong>
      If you have any questions regarding our shipping policy or how we handle personal data, please contact us at <a href="mailto:info@axldrone.com">info&#64;axldrone.com</a>.</p>
    </div>
  </div>
  
