<div class="row d-flex" style="position: relative;">
    <!-- <div class="col-md-4">
        <h6 style="float: right;margin-right: 1rem; padding-top: 30px;">Our Accreditations :</h6> 
    </div> -->
    <!-- <div class="col-md-8 accreditations"> -->
    <div class="accreditations">
        <div class="logos">
            <div class="logos-slide">
                <img src="../../../assets/image/accreditations-logo/01.png" alt="">
                <img src="../../../assets/image/accreditations-logo/02.png" alt="">
                <img src="../../../assets/image/accreditations-logo/03.png" alt="">
                <img src="../../../assets/image/accreditations-logo/04.png" alt="">
                <img src="../../../assets/image/accreditations-logo/05.png" alt="">
            </div>
            <div class="logos-slide">
                <img src="../../../assets/image/accreditations-logo/01.png" alt="">
                <img src="../../../assets/image/accreditations-logo/02.png" alt="">
                <img src="../../../assets/image/accreditations-logo/03.png" alt="">
                <img src="../../../assets/image/accreditations-logo/04.png" alt="">
                <img src="../../../assets/image/accreditations-logo/05.png" alt="">
            </div>
        </div>
    </div>
</div>

