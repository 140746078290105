import { Component, Injector, Input, ViewChild, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataService } from '../../service-api/data.service';
import { PageUserLoginComponent } from '../page-user-login.component';
import { LocalStorageService } from 'src/app/service-storage/local-storage.service';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/service-guard/auth.guard';
@Component({
  selector: 'app-pagesub-user-otp',
  templateUrl: './pagesub-user-otp.component.html',
  styleUrls: ['./pagesub-user-otp.component.scss']
})
export class PagesubUserOtpComponent implements OnInit {

  // isAlertVisible: boolean = false;
  isAlertVisible: boolean = false;
isAlertFading: boolean = false;


  ngOnInit() {
    this.showAlertForLimitedTime();
  }

  showAlertForLimitedTime() {
    this.isAlertVisible = true;
    setTimeout(() => {
      this.isAlertFading = true;
      setTimeout(() => {
        this.isAlertVisible = false;
        this.isAlertFading = false;
      }, 500); // Match the duration of the CSS transition
    }, 3000);
  }

  @Input() parentEmail = '';

  success_msg: any = null;
  error_msg: any = null;
  isFormSubmitted: boolean = false;
  
  //   controlOptions: AbstractControlOptions = {
  //     validators: this.customValidationFunction
  //  }
  formGroup:FormGroup = this.formBuilder.group({
    otp: ['',Validators.required]
  });
  // },this.controlOptions);
  
  constructor(private formBuilder:FormBuilder,private dataService:DataService,private router:Router,private _injector: Injector, private authGuard:AuthGuard){}

  goBack(){
    let parent = this._injector.get<PageUserLoginComponent>(PageUserLoginComponent);  
    parent.isSuccess = false;
  }
  
  // customValidationFunction(control: AbstractControl): ValidationErrors | null {
  //   let emailField = control.get('email')?.value;
  //   let phoneField = control.get('phone')?.value;
  //   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
  // }
  
  onFormSubmit(){
    this.isFormSubmitted =true;
    if(this.formGroup.invalid){
      return;
    }
    let parent = this._injector.get<PageUserLoginComponent>(PageUserLoginComponent);  
    this.dataService.userOtpVerify(
      this.parentEmail,
      this.formGroup.get('otp')?.value
      ).subscribe((data:any)=>{
        if(data.success){
          if(data.registration_token){
            this.authGuard.setVerifiedOnly(this.parentEmail,data.registration_token);
            this.router.navigate(['/profile']);
          }else if(data.token){
            this.authGuard.setUserLogin(data.name,data.token);
            let redirectURL = localStorage.getItem('axl_login_redirect');
            localStorage.removeItem('axl_login_redirect');
            if (redirectURL) {
              this.router.navigate([redirectURL]);
            }else{
              this.router.navigate(['/dashboard']);
            }
          }
          else{
            this.router.navigate(['']);
          }
          this.success_msg = data.msg;
          this.formGroup.reset();
          this.isFormSubmitted = false;
          setTimeout(()=>{
            this.success_msg = null;
          },5000);
        }else{
          this.error_msg = data.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }    
      );
  }
  
  // passwordHiddenToggle(){
  //   this.isPasswordHidden = !this.isPasswordHidden;
  // }
}
