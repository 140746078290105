import { Component, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { EmailService } from '../../service-api/email.service';

@Component({
  selector: 'app-pagesub-partner-register',
  templateUrl: './pagesub-partner-register.component.html',
  styleUrls: ['./pagesub-partner-register.component.scss']
})
export class PagesubPartnerRegisterComponent {
  success_msg: any = null;
  error_msg: any = null;
  isFormSubmitted: boolean = false;

  @ViewChild('captchaElemPopup') captchaElemPopup: any;
  recaptchaKey: any = environment.recaptchaSiteKey;
  public theme:any= 'light';
  public size:any= 'normal';
  public lang = 'en';
  public type:any= 'image';
  
//   controlOptions: AbstractControlOptions = {
//     validators: this.customValidationFunction
//  }
  formGroup:FormGroup = this.formBuilder.group({
    companyName: ['',Validators.required],
    companyAddress:['',Validators.required],
    phoneCountry:[''],
    phone:['',Validators.required],
    email:['',[Validators.required,Validators.email]],
    recaptcha:['',Validators.required]
  });
// },this.controlOptions);

  constructor(private formBuilder:FormBuilder,private emailService:EmailService){}

  recaptchaSuccess(data:any) {
  }

  // customValidationFunction(control: AbstractControl): ValidationErrors | null {
  //   let emailField = control.get('email')?.value;
  //   let phoneField = control.get('phone')?.value;
  //   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
  // }

  onFormSubmit(){
    this.isFormSubmitted =true;
    if(this.formGroup.invalid){
      return;
    }
    this.emailService.partnerEnquiry(
      this.formGroup.get('companyName')?.value,
      this.formGroup.get('companyAddress')?.value,
      this.formGroup.get('phoneCountry')?.value,
      this.formGroup.get('phone')?.value,
      this.formGroup.get('email')?.value,
      this.formGroup.get('recaptcha')?.value
      ).subscribe((data:any)=>{
        if(data.success){
          this.success_msg = data.msg;
          this.isFormSubmitted = false;
          this.captchaElemPopup.resetCaptcha();
          this.formGroup.reset();
          setTimeout(()=>{
            this.success_msg = null;
          },5000);
        }else{
          this.error_msg = data.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }    
      );
  }
}
