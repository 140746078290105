<section style="background: linear-gradient(#504A50, #6F6E73); padding-top: 4rem;padding-bottom: 4rem;overflow-x: hidden;">
    <div>
    <div class="container">
        <div class="row">
        <div class="col-lg-6 vstack gap-3">
            <span class="my-auto" style="padding-top: 3rem;">
                <h1>
                    <span style="color: #F97738;">axldrone</span> Management Platform
                </h1>
                <p style="font-style: normal;
                font-weight: 400;
                 margin-top:5vh;margin-bottom:5vh; text-align: justify;">
                    aakash is an end-to-end Drone Management Platform that gives complete control over your fleet to achieve autonomous inspections, reporting and data collection. This platform is integrated with axl autonomy for data exchange, and the platform provides data processing and analysis tools. It integrates with most ERPs and existing customer systems.
                </p>
                <a href="https://avm.axldrone.com/auth/login/" target="_blank" class="btn btn-secondary button-custom btn-lg" style="margin-bottom:5vh">
                    aakash login
                    <i class="bi bi-box-arrow-in-right"></i>
                </a>
            </span>
        </div>

        <div class="col-lg-6">
            <div class="">
                <img class="drone" width="100%" src="../../assets/image/platform_diagram.png">
            </div>
        </div>
    </div>
</div>
</div>
</section>

<section class="banner-feature">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/6_Flight_control.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Autonomous Flight Control</h5>
                        <p class="card-text">
                            Quickly evaluate your assets for potential issues or failures so that you can ensure the safety of workers and avoid costly repairs and shutdowns. Thorough routine and other inspections with cameras and sensors give you critical insights on your assets, while site surveys for explorations and construction projects help you with planning and budgeting.
                        </p>
                    </div> 
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/3_PROJECT AND MISSION PLANNING.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Project and Mission Planning</h5>
                        <p class="card-text">
                            Bring valuable data and insights into your project and mission planning, and have all critical areas of your physical and other assets covered for successful execution.
                        </p>
                    </div> 
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/1_Data processing and Analysis.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Data Processing and Analysis</h5>
                        <p class="card-text">
                            axl DMS extracts actionable information from the vast sea of inbound data provided by aerial inspections to deliver accurate and reliable insights for your business
                        </p>
                    </div> 
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/4_FLEET MANAGEMENT.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Fleet Management</h5>
                        <p class="card-text">
                            Manage your fleet information from acquisition to disposal to improve efficiency and ensure compliance. Achieve efficient management of your entire fleet with the help of axl UAVs/DMS.
                        </p>
                    </div> 
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/2_Data collection.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Data Collection</h5>
                        <p class="card-text">
                            axl DMS uses standard and validated techniques to glean reliable insights from input data.
                        </p>
                    </div> 
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card">
                    <img class="card-img" src="../../assets/image/platform/5_MANAGEMENT_COMPLIANCE.png" alt="Card image cap">
                    <div class="card-body text-center">
                        <h5 class="card-title">Management and Compliance</h5>
                        <p class="card-text">
                            axl ensures an ongoing process of monitoring and assessing the systems to ensure all regulatory policies and security standards are adhered to.
                        </p>
                    </div> 
                </div>
            </div>
            

        </div>
    </div>
</section>
