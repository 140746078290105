import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByFeature'
})
export class FilterByFeaturePipe implements PipeTransform {
  transform(specifications: any[], feature: string): any[] {
    if (!specifications || !feature) return [];
    return specifications.filter(spec => spec.additional_hardware === feature);
  }
}
