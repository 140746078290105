<!--Banner Start-->
<div class="terms">
    <div class="row mx-0 h-100 justify-content-center align-items-center ">
      <div class="col-md-12 col-lg-7 col-xl-6 text-center banner">
        <h1 data-aos="fade-up" data-aos-duration="1000">Terms Of Use

        </h1>

      </div>
    </div>
  </div>
  <!--Banner End-->

  <!--Section Category Start-->
  <div class="terms_body">
    <div class="container">
      <p class="px-0">
        Welcome to the axldrone.com website (the “Site”). By accessing this Site, you agree to be bound by the terms and conditions below (the “Terms”). If you do not agree to all of the Terms, please do not use the Site. As a software development company, axldrone may from time to time modify or revise the Terms by updating this Web page. Your use of our Site following any such change constitutes your agreement to follow and be bound by the Terms as changed. If any change is unacceptable to you, your only recourse is to terminate your use of the Site.
      </p>

        <h4>Copyright And Trademark Notice</h4>
        <p>This Site and its contents, including, but not limited to, text, photographs, graphics, illustrations, video, sound, and other digital marketing material (all such content collectively referred to as “Content”) are protected under US and international copyright laws and are the property of axldrone.com or its third-party licensors. All rights reserved. All logos, splash screens, page headers, custom graphics, and button icons displayed on this Site are service marks, trademarks, and/or trade dress (collectively, “Marks”) of axldrone.com or its third-party licensors. Except as specifically permitted herein, copying, distributing, transmitting, displaying, modifying, selling, or participating in the sale of, or otherwise exploiting or using any Content or any Marks in any form or by any means without the express written permission of axldrone.com is prohibited and may violate the copyright or trademark laws of US and/or other countries. The Site and its Contents are intended solely for personal, noncommercial use by the users of our Site. You may download or copy the Contents displayed on the Site for the sole purpose of using the Site as a personal resource, provided that you maintain any notices contained in the Content, such as all copyright notices, trademark legends, or other proprietary rights notices. No right, title, or interest in any downloaded materials is transferred to you as a result of any such downloading or copying.</p>


        <h4>Your Comments</h4>
        <p>axldrone is a provider of software development services and appreciates your comments, remarks, feedback, suggestions, ideas, and other submissions you disclose or transmit to us (collectively, “Comments”). You grant axldrone.com and its affiliates a perpetual, irrevocable, worldwide, royalty-free right and license to use, reproduce, modify, adapt, publish, translate, create derivative works of, distribute, publicly perform, and publicly display (in whole or in part) your Comments, your name, and any related copyrights, moral rights, or other intellectual property rights.</p>

        <h4>Privacy</h4>
        <p>As a software development company, axldrone values and respects the privacy of individuals who visit the Site or provide Comments to us. Our privacy policy (the “Privacy Policy”), which you may view at <a target="_blank" href="https://www.axldrone.com/privacy-policy" class="link">https://www.axldrone.com/privacy-policy</a> is incorporated herein by reference. By accepting these Terms, you expressly consent to the use and disclosure of your personally identifiable and other information as described in the Privacy Policy.</p>

        <h4>Linked Sites</h4>
        <p>This Site may contain links to other websites (“Linked Sites”). axldrone.com does not operate or control any information, products, or services on the Linked Sites and does not endorse or approve any products or information offered at Linked Sites. You acknowledge and agree that your access or use of any Linked Site is at your own risk.</p>

        <h4>Disclaimer</h4>
        <p>This Site, its contents, and all information, products, and high-performance digital marketing services contained in or offered through this Site are provided on an “as is” and “as available” basis without representations or warranties of any kind. axldrone.com expressly disclaims all such representations and warranties, either express or implied, including, without limitation, warranties of title, merchantability, fitness for a particular purpose, or noninfringement and any implied warranties arising from course of dealing or course of performance. axldrone.com does not warrant that this Site or its contents will be complete, accurate, uninterrupted, secure, or error free or that the Site or the server that makes it available are free of viruses or other harmful components. All information on the Site is subject to change without notice.</p>

        <h4>Indemnification</h4>
        <p>You agree to defend, indemnify, and hold axldrone.com harmless from and against any and all claims, losses, damages, liabilities, costs, and expenses, including attorneys’ fees, arising from or related to your (i) use of the Site or (ii) violation of any of these Terms.</p>

        <h4>Limitation Of Liability</h4>
        <p>In no event shall axldrone.com be liable for any direct, indirect, consequential, special, or incidental damages arising out of or related to your use of or inability to use this Site or paid search, SEO and social media services purchased or obtained through this Site, whether in an action under contract, negligence, or any other theory, even if axldrone.com has been advised of the possibility of such damages. axldrone.com’s total liability for any claim arising from or related to your use of this Site shall not exceed one hundred dollars (US$100).</p>

        <h4>Miscellaneous</h4>
        <p>These Terms are governed by and shall be construed in accordance with the laws of the Province of Ontario without giving effect to any principles of conflicts of law. If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. These Terms are effective unless and until terminated by axldrone.com.</p>

    </div>

  </div>
  <!--Section Category End-->