<!-- video banner -->
<section>
    <div class="container-fluid p-0">
        <div class="embed-responsive embed-responsive-16by9">
            <video style="width:100%" class="embed-responsive-item" [src] = "environment.assetUrl+'/'+innovation.video_file" autoplay [muted]="'muted'" loop poster="../../assets/image/axldrone-loading.png">
                Your browser does not support HTML5 video.
            </video>
        </div>
    </div>
</section>

<!-- innovation details -->
<section class="innovation-section" style="padding-top: 4rem;padding-bottom: 4rem;text-align: justify;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pb-4 d-none d-lg-block">
                <img class="img-fluid" src="{{environment.assetUrl}}/{{innovation.image}}" alt="">
            </div>
            <div class="col-lg-6">
                <h4 style="color: #F97738;">{{innovation.title}}</h4>
                <div [innerHtml]="innovation.description | sanitizeHtml"></div>
                <button (click)="downloadFile()" class="btn btn-secondary button-custom">
                    General Brochure
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.42857 12L12 16.8366L16.5714 12.0469M12 4V16.5714M5.14285 20H18.8571" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</section>

<!-- products under innovation -->
<section style="padding-top: 6rem;padding-bottom: 4rem; background-color: #C4C4C4;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-6 col-lg-3 d-flex flex-column h-100" *ngFor="let innovationProduct of innovationProducts">
                <div class="align-self-end">
                <a  [routerLink]="['/product',innovationProduct.slug]" style="text-decoration: none;" >
                    <div class="card" style="border: 0;background-color: #C4C4C4;">
                        <div class="">
                            <img class="card-img-top" src="{{environment.assetUrl}}/{{innovationProduct.image}}" alt="Card image cap" style="border-radius: 1rem;border-color: #F97738;">
                        </div>
                        <div class="card-body text-center" style="color: #4B4A50;">
                            <h5 class="card-title">{{innovationProduct.title}}</h5>
                        </div>
                    </div>
                </a></div>
            </div>
        </div>
    </div>
</section>

<!-- customize -->
<section style="background: linear-gradient(90deg, #1E1D24 -22.66%, #F97738 100%);">
    <div class="container py-5">
        <div class="customise-btn hstack gap-2 justify-content-center">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.64" d="M25.7533 3.75854C25.7533 3.75854 25.8883 6.05688 27.9167 8.08355C29.9433 10.1119 32.2417 10.2469 32.2417 10.2469M14.8333 23.9802L12.0217 21.1669" stroke="#F8F8F8" stroke-width="2"/>
                <path d="M25.7533 3.75845L26.835 2.67679C27.6956 1.81638 28.8628 1.3331 30.0798 1.33325C31.2967 1.33341 32.4638 1.81699 33.3242 2.67762C34.1846 3.53825 34.6679 4.70542 34.6677 5.92238C34.6675 7.13933 34.184 8.30638 33.3233 9.16679L32.24 10.2485L22.3 20.1901C21.6267 20.8635 21.29 21.2001 20.9183 21.4901C20.4803 21.8317 20.0064 22.1245 19.505 22.3635C19.08 22.5651 18.6283 22.7151 17.725 23.0168L14.8333 23.9818L12.9617 24.6051C12.7435 24.6782 12.5093 24.689 12.2853 24.6362C12.0614 24.5834 11.8566 24.4693 11.694 24.3065C11.5314 24.1437 11.4174 23.9388 11.3649 23.7148C11.3123 23.4908 11.3234 23.2565 11.3967 23.0385L12.02 21.1685L12.985 18.2768C13.285 17.3735 13.435 16.9218 13.6383 16.4968C13.8783 15.9951 14.1717 15.5201 14.5117 15.0835C14.8017 14.7118 15.1383 14.3751 15.8117 13.7001L25.7533 3.76012V3.75845Z" stroke="#F8F8F8" stroke-width="2"/>
                <path opacity="0.64" d="M34.6667 17.9999C34.6667 27.2049 27.205 34.6666 18 34.6666C8.795 34.6666 1.33333 27.2049 1.33333 17.9999C1.33333 8.79492 8.795 1.33325 18 1.33325" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <a [routerLink]="['/lets-talk']" style="text-decoration: none;"><h2>Connect With Us To Customize Your Drone</h2></a>
        </div>
    </div>
</section>