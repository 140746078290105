import { Component } from '@angular/core';

@Component({
  selector: 'app-page-axlviman',
  standalone: true,
  imports: [],
  templateUrl: './page-axlviman.component.html',
  styleUrl: './page-axlviman.component.scss'
})
export class PageAxlvimanComponent {

}
