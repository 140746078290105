import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-page-user-dashboard',
  templateUrl: './page-user-dashboard.component.html',
  styleUrls: ['./page-user-dashboard.component.scss']
})
export class PageUserDashboardComponent implements OnInit {
  environment=environment;

  brochureFile:string='';

  constructor(private dataService:DataService,private renderer: Renderer2){
  }

  ngOnInit(): void {
    this.loadBrochure();
  }

  loadBrochure(){
    this.dataService.userDetailBrochure().subscribe((data:any)=>{
      if(data.success){
        this.brochureFile = data.file;
      }
    });
  }

  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.assetUrl+'/'+this.brochureFile);
    //link.setAttribute('download', `axldrone_general_brochure.pdf`);
    link.click();
    link.remove();
  }

}
