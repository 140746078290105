import { Component } from '@angular/core';

@Component({
  selector: 'app-page-about-us',
  templateUrl: './page-about-us.component.html',
  styleUrl: './page-about-us.component.scss'
})
export class PageAboutUsComponent {

}
