import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-partner',
  templateUrl: './page-partner.component.html',
  styleUrls: ['./page-partner.component.scss']
})
export class PagePartnerComponent {

}
