
<section>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6 side-view p-0 d-none d-lg-block">
                <img src="../../assets/image/login_left.png" alt="Let's Talk" class="img-fluid" width="100%" >
            </div>

            <div class="col-lg-6 login-view p-5">
                <div *ngIf="!isSuccess" class="container px-5">
                    <h2 class="pt-5">axl Platform Access
                    </h2>
                    <h6>First time user ? If not, use previously used email</h6>

                    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" style="padding-top: 4rem;">
                        <div class="form-group mb-1">
                            <label>Email</label>
                            <input type="text" autocomplete="off" formControlName="email" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('email')?.errors}" class="form-control" placeholder="Enter your email">
                        </div>
                        <div class="text-right">
                            <!-- <span class="alert in success-green" *ngIf="success_msg">
                                {{success_msg}}
                            </span> -->
                            <span class="alert in error-red">
                                {{error_msg}}
                            </span>
                            
                        </div>

                        <!-- <button [disabled]="!(success_msg===null && error_msg===null)" type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem; width: 100%;">
                            Send OTP
                        </button> -->
                        <button [disabled]="!(success_msg === null && error_msg === null) || isLoading" type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem; width: auto;">
                            <span *ngIf="!isLoading">Send OTP</span>
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                               
                            </span>
                            <span  *ngIf="isLoading">Sending OTP...</span>
                          </button>
                    </form>
                </div>

                <app-pagesub-user-otp *ngIf="isSuccess" [parentEmail]="email"></app-pagesub-user-otp>
                

            </div>

        </div>
    </div>
</section>
