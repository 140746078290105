<section class="py-5" style="background: linear-gradient(360deg, #3E3D42 0%, #414044 30%, #444444 100%);">
  <div class="container">
    <h1 class="product-title">Product Registration Page</h1>
    <h2 class="product-subtitle">Product Warranty Registration</h2>
    <p class="paragraph-text">
      Once you receive the products from axldrone or our dealers, please follow the following process to register your
      products to activate warranty protection. We will not be able to support or cover warranty claims for unregistered
      products.
    </p>

    <div class="col-lg-12 px-5 py-4">
      <h4 class="pb-3 pt-5" style="color: #F97738;">Make an Enquiry</h4>

      <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
        <div class="row">
          <div class="col">
            <label class="label-text">First Name <span class="required-star">*</span></label>
            <input type="text" formControlName="firstName" class="input-bottom-line"
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('firstName')?.errors}" required />
          </div>
          <div class="col">
            <label class="label-text">Last Name <span class="required-star">*</span></label>
            <input type="text" formControlName="lastName" class="input-bottom-line"
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('lastName')?.errors}" required />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="label-text">Phone Number <span class="required-star">*</span></label>
            <input type="text" formControlName="phone" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('phone')?.errors}" />
          </div>
          <div class="col">
            <label class="label-text">Email <span class="required-star">*</span></label>
            <input type="email" formControlName="email" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('email')?.errors}" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="label-text">Street Address <span class="required-star">*</span></label>
            <input type="text" formControlName="address" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('address')?.errors}" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="label-text">City <span class="required-star">*</span></label>
            <input type="text" formControlName="city" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('city')?.errors}" />
          </div>
          <div class="col">
            <label class="label-text">State/Region <span class="required-star">*</span></label>
            <input type="text" formControlName="state" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('state')?.errors}" />
          </div>
          <div class="col">
            <label class="label-text">Postal Code <span class="required-star">*</span></label>
            <input type="text" formControlName="postalCode" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('postalCode')?.errors}" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="label-text">Country/Region <span class="required-star">*</span></label>
            <input type="text" formControlName="country" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('country')?.errors}" />
          </div>

        </div>

        <div class="row">
          <div class="col">
            <label class="label-text">Product <span class="required-star">*</span></label>
            <select formControlName="product" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('product')?.errors}">
              <option value="">Select</option>
              <option *ngFor="let product of products" [value]="product.slug">
                {{ product.title }}
              </option>
            </select>

            <!-- <select formControlName="product" class="input-bottom-line" required>
                <option value="">Select</option>
                <option value="Product 1">Product 1</option>
                <option value="Product 2">Product 2</option>
              </select> -->
          </div>
          <div class="col">
            <label class="label-text">Product ID <span class="required-star">*</span></label>
            <input type="text" formControlName="productId" class="input-bottom-line" required
              [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('productId')?.errors}" />
          </div>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" formControlName="agreeToTerms" class="checkbox-input" required
            [ngClass]="{'has_checkbox_error ' : isFormSubmitted && formGroup.get('agreeToTerms')?.errors}" />
          <label class="input-label-text">
            I agree to the axldrone warranty
            <a href="/terms-of-use" target="_parent" class="terms-link">terms</a><span class="required-star">*</span>
          </label>
        </div>

        <p class="bottom-para">
          axldrone India Pvt Ltd is committed to protecting and respecting your privacy, and we’ll only use your
          personal
          information to administer your account and to provide the products and services you requested from us. From
          time
          to time, we would like to contact you about our products and services, as well as other content that may be of
          interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would
          like us to contact you:
        </p>

        <div class="checkbox-container">
          <input type="checkbox" formControlName="marketingConsent" class="checkbox-input" />
          <label class="input-label-text">
            I agree to receive Marketing communications like Phone Calls, SMS communications and Emails from axldrone
            Pvt Ltd.
          </label>
        </div>

        <p class="bottom-para">
          In order to provide you the content requested, we need to store and process your personal data. If you consent
          to
          us storing your personal data for this purpose, please tick the checkbox below.
        </p>

        <div class="checkbox-container">
          <input type="checkbox" formControlName="personalData" class="checkbox-input" required
            [ngClass]="{'has_checkbox_error ' : isFormSubmitted && formGroup.get('personalData')?.errors}" />
          <label class="input-label-text">
            I agree to allow axldrone India Pvt Ltd to store and process my personal data.
            <span class="required-star">*</span>
          </label>
        </div>
        <!-- <div class="form-group">
            <ngx-recaptcha2 #captchaElemPopup siteKey="{{recaptchaKey}}" (success)="recaptchaSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha"></ngx-recaptcha2>
        </div> -->
        <div class="form-group">
          <ngx-recaptcha2 #captchaElemPopup siteKey="{{recaptchaKey}}" (success)="recaptchaSuccess($event)"
            [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha"></ngx-recaptcha2>
        </div>
        <div class="text-right">
          <span class="alert in success-green" *ngIf="success_msg">
            {{success_msg}}
          </span>
          <span class="alert in error-red" *ngIf="error_msg">
            {{error_msg}}
          </span>
          <button *ngIf="success_msg===null && error_msg===null" type="submit" class="btn btn-primary">Submit</button>
        </div>

      </form>
    </div>
  </div>
</section>