import { Component, OnInit } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-product',
  templateUrl: './page-product.component.html',
  styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent implements OnInit {
  environment=environment;

  multirotors:{
    id: number,
    title: string,
    slug: string,
    image: string
  }[]=[];

  vtols:{
    id: number,
    title: string,
    slug: string,
    image: string
  }[]=[];

  accessories:{
    id: number,
    title: string,
    slug: string,
    image: string
  }[]=[];

  constructor(private dataService:DataService){
  }

  ngOnInit(): void {
    this.loadMultirotor();
    this.loadVtol();
    this.loadAccessories();
  }

  loadMultirotor(){
    this.dataService.storeMultirotorList().subscribe((data:any)=>{
      if(data.success){
        this.multirotors = data.products;
      }
    });
  }

  loadVtol(){
    this.dataService.storeVtolList().subscribe((data:any)=>{
      if(data.success){
        this.vtols = data.products;
      }
    });
  }

  loadAccessories(){
    this.dataService.storeAccessoryList().subscribe((data:any)=>{
      if(data.success){
        this.accessories = data.products;
      }
    });
  }

}
