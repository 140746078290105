import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = environment.appName;

  constructor(router: Router){
    //load google analytics script
    this.loadGoogleAnalyticsScript();

    //call google analytics on router navigation
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', environment.googleAnalyticsTrackingId, {'page_path': event.urlAfterRedirects});
      }
    });
  }

  private loadGoogleAnalyticsScript() {
    /* 
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-232708465-1"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'UA-232708465-1');
    </script> 
    */

    // Google Analytics Above Script Equivalent
    let gtagScript1 = document.createElement('script');
    gtagScript1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingId}`;
    gtagScript1.async = true;
    const head  = document.getElementsByTagName('head')[0];  
    let gtagScript2 = document.createElement('script');
    gtagScript2.innerHTML=`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    `
    if (head !== null && head !== undefined) {
      head.appendChild(gtagScript1);
      head.appendChild(gtagScript2);
    }
  }
}