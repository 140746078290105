import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageTermsComponent } from './page-terms/page-terms.component';
import { PagePrivacyComponent } from './page-privacy/page-privacy.component';
import { PagePlatformComponent } from './page-platform/page-platform.component';
import { PageAxlvimanComponent } from './page-axlviman/page-axlviman.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageProductComponent } from './page-product/page-product.component';
import { PageProductDetailComponent } from './page-product-detail/page-product-detail.component';
import { PageInnovationComponent } from './page-innovation/page-innovation.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { PageCoeComponent } from './page-coe/page-coe.component';
import { LayoutWithoutAddressComponent } from './layout-without-address/layout-without-address.component';
import { PageCareerComponent } from './page-career/page-career.component';
import { PageNewsEventComponent } from './page-news-event/page-news-event.component';
import { PagePartnerComponent } from './page-partner/page-partner.component';
import { PagePartnerLoginComponent } from './page-partner-login/page-partner-login.component';
import { PageLetstalkComponent } from './page-letstalk/page-letstalk.component';
import { PageCoeEnquiryComponent } from './page-coe-enquiry/page-coe-enquiry.component';
import { PageUserLoginComponent } from './page-user-login/page-user-login.component';
import { PageUserProfileComponent } from './page-user-profile/page-user-profile.component';
import { PageStoreComponent } from './page-store/page-store.component';
import { PageUserDashboardComponent } from './page-user-dashboard/page-user-dashboard.component';
import { PagePartnerDashboardComponent } from './page-partner-dashboard/page-partner-dashboard.component';
import { PageLeaseComponent } from './page-lease/page-lease.component';
import { AuthGuard } from './service-guard/auth.guard';

import { PageAboutUsComponent } from './page-about-us/page-about-us.component';
import { PageShippingpolicyComponent } from './page-shippingpolicy/page-shippingpolicy.component';
import { PageRefundpolicyComponent } from './page-refundpolicy/page-refundpolicy.component';
import { PageNewsComponent } from './page-news/page-news.component';
import { PageProductRegistrationComponent } from './page-product-registration/page-product-registration.component';
const routes: Routes = [
{
  path:'contact-us',
    component: LayoutWithoutAddressComponent,
    children:[
      {path:'',component:PageContactComponent},
    ]
},
{
  path:'drone-coe',
    component: LayoutWithoutAddressComponent,
    children:[
      {path:':slug',component:PageCoeComponent},
      {path:':slug/enquiry',component:PageCoeEnquiryComponent},
    ]
},
{
  path:'',
  component: LayoutComponent,
  children:[
    {path:'',component:PageHomeComponent},
    {path:'terms-of-use',component:PageTermsComponent},
    {path:'privacy-policy',component:PagePrivacyComponent},
    {path:'aakash-platform',component:PagePlatformComponent},
    {path:'viman',component:PageAxlvimanComponent},
    {path:'innovation/:slug',component:PageInnovationComponent},
    {path:'product',component:PageProductComponent},
    {path:'product/:slug',component:PageProductDetailComponent},
    {path:'careers',component:PageCareerComponent},
    {path:'events',component:PageNewsEventComponent},
    {path:'events/:slug',component:PageNewsEventComponent},
    {path:'partner',component:PagePartnerComponent},
    {path:'product-registration',component:PageProductRegistrationComponent},
    {path:'lets-talk',component:PageLetstalkComponent},
    {path:'partner/login',component:PagePartnerLoginComponent},
    {path:'login',component:PageUserLoginComponent},
    {path:'profile',component:PageUserProfileComponent,canActivate:[AuthGuard]},
    {path:'axl-store',component:PageStoreComponent},
    {path:'axl-store/:innerslug',component:PageStoreComponent},
    {path:'axl-store/:innerslug/:slug',component:PageStoreComponent},
    {path:'about-us',component:PageAboutUsComponent},
    {path:'dashboard',component:PageUserDashboardComponent,canActivate:[AuthGuard]},
    {path:'partner/dashboard',component:PagePartnerDashboardComponent,canActivate:[AuthGuard]},
    {path:'shipping-policy',component:PageShippingpolicyComponent},
    {path:'refund-policy',component:PageRefundpolicyComponent},
    {path:'news',component:PageNewsComponent},
    {path:'axl-lease',component:PageLeaseComponent,canActivate:[AuthGuard]},
    {path:'**',pathMatch:'full',component:PageNotfoundComponent},



  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
