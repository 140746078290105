import { Component } from '@angular/core';

@Component({
  selector: 'app-pagesub-accreditations',
  // standalone: true,
  // imports: [],
  templateUrl: './pagesub-accreditations.component.html',
  styleUrl: './pagesub-accreditations.component.scss'
})
export class PagesubAccreditationsComponent {

}
