<section class="py-5" style="background: linear-gradient(180deg, #4B4A50 0%, #3D3C43 36.98%, #4B4A50 100%);">
    <div class="container">
        <h3>Multi Rotor</h3>
        <div class="row pt-3">
            <div class="col-6 col-lg-4" *ngFor="let item of multirotors">
                <a [routerLink]="['/product',item.slug]" style="text-decoration: none;">
                    <div class="card" style="border: 0;background: transparent;">
                        <div>
                            <img class="card-img-top" src="{{environment.assetUrl}}/{{item.image}}" alt="{{item.title}}" style="border-radius: 1rem;border-color: #F97738;">
                        </div>
                            <div class="card-body text-center">
                        <h5 class="card-title">{{item.title}}</h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<section class="py-5" style="background:#37363d;">
    <div class="container">
        <h3>VTOL</h3>
        <div class="row pt-3">
            <div class="col-6 col-lg-4" *ngFor="let item of vtols">
                <a [routerLink]="['/product',item.slug]" style="text-decoration: none;">
                    <div class="card" style="border: 0;background: transparent;">
                        <div>
                            <img class="card-img-top" src="{{environment.assetUrl}}/{{item.image}}" alt="{{item.title}}" style="border-radius: 1rem;border-color: #F97738;">
                        </div>
                            <div class="card-body text-center">
                        <h5 class="card-title">{{item.title}}</h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<section class="py-5" style="background:#25242a;">
    <div class="container">
        <h3>Accessories</h3>
        <div class="row pt-3">
            <div class="col-6 col-lg-4" *ngFor="let item of accessories">
                <a [routerLink]="['/product',item.slug]" style="text-decoration: none;">
                    <div class="card" style="border: 0;background: transparent;">
                        <div>
                            <img class="card-img-top" src="{{environment.assetUrl}}/{{item.image}}" alt="{{item.title}}" style="border-radius: 1rem;border-color: #F97738;">
                        </div>
                            <div class="card-body text-center">
                        <h5 class="card-title">{{item.title}}</h5>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>