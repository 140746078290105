import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-page-news-event',
  templateUrl: './page-news-event.component.html',
  styleUrls: ['./page-news-event.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageNewsEventComponent implements OnInit  {
  environment=environment;

  paused = false;
	unpauseOnArrow = false;
	pauseOnIndicator = false;
	pauseOnHover = true;
	pauseOnFocus = true;

  @ViewChild('carousel', { static: true })
  carousel!: NgbCarousel;

  activeMedia: any = '';
  activeSlug:string='';
  
  items:{
    id: number,
    title: string,
    slug: string,
    thumbnail: string
  }[]=[];

  activeItemDetail:{
    id: number,
    title: string,
    slug: string,
    description: string,
    date:string,
    gallery: { id: number, slug: string, file: string|undefined, file_type: string}[],
  }={id:0,title:'',slug:'',description:'',date:'',gallery:[]};

  constructor(private dataService:DataService,private activeRoute: ActivatedRoute,private router: Router){
  }

  ngOnInit(): void {
    this.loadItems();
    this.activeRoute.params.subscribe(params => {
      this.activeSlug = params['slug'] ?? '';
      if(this.activeSlug!=''){     
        this.loadDetail(this.activeSlug);
      }
    });
  }

  loadItems(){
    this.dataService.newsEventList().subscribe((data:any)=>{
      if(data.success){
        this.items=data.news_event_data;
        if(this.items.length && this.activeSlug==''){
          this.router.navigate(['/news-and-events',this.items[0].slug],{skipLocationChange: true});
        }
      }
    });
  }

  loadDetail(slug:string){
    this.dataService.newsEventItemDetail(slug).subscribe((data:any)=>{
      if(data.success){
        this.activeItemDetail=data.news_event_data;
        this.setActiveImageOnClick(this.activeItemDetail.gallery[0]);
      }else{
        this.router.navigate(['/404'],{skipLocationChange: true});
      }
    },(error: any)=>{
      this.router.navigate(['/404'],{skipLocationChange: true});
    });
  }

  setActiveImageOnClick(media:any){
    this.activeMedia = media;
  }
  togglePaused() {
		if (this.paused) {
			this.carousel.cycle();
		} else {
			this.carousel.pause();
		}
		this.paused = !this.paused;
	}

	onSlide(slideEvent: NgbSlideEvent) {
		if (
			this.unpauseOnArrow &&
			slideEvent.paused &&
			(slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
		) {
			this.togglePaused();
		}
		if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
			this.togglePaused();
		}
	}



}
