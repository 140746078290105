<section>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6 register-view p-5">
                <app-pagesub-partner-register></app-pagesub-partner-register>
            </div>

            <div class="col-lg-6 login-view p-5">
                <div class="container px-5">
                    <h2 class="pt-5">Welcome to the</h2>
                    <h2>axl Partner Portal</h2>

                    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" style="padding-top: 3rem;">
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" autocomplete="off" formControlName="username" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('username')?.errors}" class="form-control" placeholder="Enter your username">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input [type]="'password'" autocomplete="off" formControlName="password"  [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('password')?.errors}" class="form-control" placeholder="Enter your password">
                            <!-- <input [type]="isPasswordHidden ? 'password' : 'text'" autocomplete="off" formControlName="password"  [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('password')?.errors}" class="form-control" placeholder="Enter your password"> -->
                        </div>
                        <div class="text-right">
                            <span class="alert in success-green" *ngIf="success_msg">
                                {{success_msg}}
                            </span>
                            <span class="alert in error-red" *ngIf="error_msg">
                                {{error_msg}}
                            </span>
                            <button type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem; width: 7rem;">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
                

            </div>

        </div>
    </div>
</section>
