import { Component } from '@angular/core';

@Component({
  selector: 'app-page-terms',
  templateUrl: './page-terms.component.html',
  styleUrls: ['./page-terms.component.scss']
})
export class PageTermsComponent {

}
