import { Component } from '@angular/core';

@Component({
  selector: 'app-page-partner-password-reset',
  templateUrl: './page-partner-password-reset.component.html',
  styleUrls: ['./page-partner-password-reset.component.scss']
})
export class PagePartnerPasswordResetComponent {

}
