<section style="background: linear-gradient(#504A50, #6F6E73); padding-top: 4rem;padding-bottom: 4rem;overflow-x: hidden;">
    <div>
    <div class="container">
        <div class="row">
        <div class="col-lg-6 vstack gap-3">
            <span class="my-auto" style="padding-top: 3rem;">
                <h1>
                    <span style="color: #F97738;">axl</span>viman
                </h1>
                <p style="font-style: normal;
                font-weight: 400;
                 margin-top:5vh;margin-bottom:5vh; text-align: justify;">
                 axlviman is an iOS based mobile application with a full feature set for managing and directing drone operations. axlviman is primarily made with the goal of improving drone-user experience. It is a potent tool for both inexperienced and experienced drone operators which manages project-related data and tracks their flying plans. DGCA regulations and guidelines are integrated into the axlviman app ensuring the users are up-to-date with current regulations and compliances.
                </p>
                <!-- # Axlviman App Plan offer price card  -->
                <div class="offer-box">
                    <div class="offer-price" id="offerPrice">Total Amount</div>
                    <div class="price-line" id="priceLine"></div>
                    <!-- <div class="price-row" id="priceRow">
                        <div class="original-price" id="originalPrice">00</div>
                        <div class="discount-badge" id="discountBadge">0% OFF</div>
                    </div> -->
            <div class="new-price" id="newPrice">1800/-<span class="year"> year</span></div>
                    <div class="trial-info" id="trialInfo">Plan duration: 365 days</div>
                </div>

                <div class="app-store-div">
                    <!-- <span class="app-store-span">Get Our App On App Store</span> -->
                    <a href="https://apps.apple.com/in/app/axlviman/id6695735424" target="_blank"><img class="app-store-btn" src="../../assets/image/app-store-btn.svg" alt="App Store"></a>
                </div>
            </span>
        </div>

        <div class="col-lg-6">
            <div class="">
                <img class="drone" width="100%" src="../../assets/image/axlviman_app.png">
            </div>
        </div>
    </div>
</div>
</div>
</section>

