import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalService } from '../service-storage/local.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  permissions = new Map<string, { redirectUrl: string, allowedUrls: string[]}>([
    // ['', {
    //   redirectUrl:'page-not-found',
    //   allowedUrls:[
    //     // 'dashboard',
    //     // 'partner/dashboard'
    //     'axl-store',
    //     'axl-lease'
    // ]}],
    ['verified-only', {  //no profile, but otp is verified
      redirectUrl:'lets-talk',
      allowedUrls:[
        'profile'
    ]}],
    ['user', {
      redirectUrl:'lets-talk',
      allowedUrls:[
        'logout',
        'profile',
        'dashboard',
        'axl-store',
        'axl-store/:innerslug',
        'axl-store/:innerslug/:slug',
    ]}],
    ['partner',  {
      redirectUrl:'lets-talk',
      allowedUrls:[
        'logout',
        'partner/dashboard',
        'axl-store',
        'axl-store/:innerslug',
        'axl-store/:innerslug/:slug',
        'axl-lease'
    ]}]
]);

  constructor(private localService: LocalService,private router:Router){ }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // return true;
      if(state.url!='/profile'){
        localStorage.setItem('axl_login_redirect',state.url);
      }
      
      let perm = this.permissions.get(this.getLoginType());
      if(perm == undefined){
        this.router.navigate(['/login']);
        return false;
      }
      if(route.routeConfig){
        if(perm.allowedUrls.indexOf(route.routeConfig.path??'')!==-1){
          return true;
        }
      }
      this.router.navigate([perm.redirectUrl]);
      return false;
      
  }

  getPermission(url:string):boolean{
    let perm = this.permissions.get(this.getLoginType());
    if(perm == undefined){
      return false;
    }
    if(perm.allowedUrls.indexOf(url)!==-1){
      return true;
    }
    return false;
  }

  isLoggedin():boolean{
    let token = localStorage.getItem('axl_login_token');
    if (token===null){
      return false;
    }else{
      return true;
    }
  }

  setVerifiedOnly(email:string, token:string){
    localStorage.setItem('axl_login_type','verified-only');
    localStorage.setItem('axl_reg_email',email);
    localStorage.setItem('axl_reg_token',token);
  }

  setUserLogin(name:string,token:string){
    localStorage.setItem('axl_login_type','user');
    localStorage.setItem('axl_login_name',name);
    localStorage.setItem('axl_login_token',token);
  }

  setPartnerLogin(name:string,token:string){
    localStorage.setItem('axl_login_type','partner');
    localStorage.setItem('axl_login_name',name);
    localStorage.setItem('axl_login_token',token);
  }

  

  private getLoginType():string{
    let type = localStorage.getItem('axl_login_type');
    if (type===null){
      return '';
    }else{
      return type;
    }
  }

  getLoginName():string|null{
    return localStorage.getItem('axl_login_name');
  }

  getLoginToken():string|null{
    return localStorage.getItem('axl_login_token');
  }
  
  getRegistrationEmail():string|null{
    return localStorage.getItem('axl_reg_email');
  }

  getRegistrationToken():string|null{
    return localStorage.getItem('axl_reg_token');
  }

  logout():void{
    localStorage.removeItem('axl_login_type');
    localStorage.removeItem('axl_login_name');
    localStorage.removeItem('axl_login_token');
    this.router.navigate(['/']);
  }

}
