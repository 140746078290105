import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../service-storage/local-storage.service';
import { AuthGuard } from '../service-guard/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,private authGuard:AuthGuard) { }

  /* innovation list  */
  innovationList(){
    // return this.http.get<any>(`${environment.apiUrl}/drone-category`);
    return this.http.get<any>(`${environment.apiUrl}/drone-category`);
  }

  // website ads
  advertisementList(){
    // return this.http.get<any>(`${environment.apiUrl}/drone-category`);
    return this.http.get<any>(`${environment.apiUrl}/axl-ads`);
  }

  /* innovation item detail */
  innovationDetail(slug: string){
    return this.http.get<any>(`${environment.apiUrl}/drone-category`,
    {
      params: {
        slug:slug
      }
    });
  }

  /* innovation products */
  innovationProductList(droneCategorySlug: string){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        drone_category:droneCategorySlug,
      }
    });
  }

  /* store item list */
  storeList(){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`);
  }

  /* store Multirotor list */
  storeMultirotorList(){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        type:'multirotor'
      }
    }
    );
  }

  /* store Vtol list */
  storeVtolList(){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        type:'vtol'
      }
    }
    );
  }

  /* store item detail */
  storeItemDetail(slug: string){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        slug:slug
      }
    });
  }

  /* store Multirotor,Vtol list */
  storeDroneList(){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        type:'multirotor,vtol'
      }
    }
    );
  }

  /* store Accessories list */
  storeAccessoryList(){
    return this.http.get<any>(`${environment.apiUrl}/axl-store`,
    {
      params: {
        type:'accessory'
      }
    }
    );
  }

  /* drone coe list  */
  droneCoeList(){
    return this.http.get<any>(`${environment.apiUrl}/coe`);
  }
  
  /* drone coe item detail */
  droneCoeItemDetail(slug: string){
    return this.http.get<any>(`${environment.apiUrl}/coe`,
    {
      params: {
        slug:slug
      }
    });
  }

  /* news & events list */
  newsEventList(){
    return this.http.get<any>(`${environment.apiUrl}/news-and-events`);
  }

  /* news & events item detail */
  newsEventItemDetail(slug: string){
    return this.http.get<any>(`${environment.apiUrl}/news-and-events`,
    {
      params: {
        slug:slug
      }
    });
  }

  /* career list */
  careerList(){
    return this.http.get<any>(`${environment.apiUrl}/careers`);
  }

  /* partner login */
  partnerLogin(username:string,password:string){
    let body={
        username:username, 
        password:password, 
    };
    return this.http.post<any>(`${environment.apiUrl}/partner-login`,body);
  }

  /* user OTP send */
  userOtpSend(email:string){
    let param={
      email:email
    };
    return this.http.get<any>(`${environment.apiUrl}/user-otp-generator`,{params:param});
  }

  /* user OTP verify */
  userOtpVerify(email:string, otp:string){
    let body={
      email: email,
      otp: otp
    };
    return this.http.post<any>(`${environment.apiUrl}/user-login`,body);
  }

  /* profile data set */
  userProfileSet(firstName:string, lastName:string, email:string,phoneCountryCode: string,phone:string,profession:string,usage:string ){
    let body={
      registration_token: this.authGuard.getRegistrationToken(),
      token:this.authGuard.getLoginToken(),
      first_name:firstName,
      last_name:lastName,
      email:email,
      phone_country_code:phoneCountryCode,
      phone_number:phone,
      profession:profession,
      drone_usage_area:usage
    }
    return this.http.post<any>(`${environment.apiUrl}/add-user`,body);
  }

  /* profile data get */
  userProfileGet(){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    let body={
    }
    return this.http.post<any>(`${environment.apiUrl}/user-view`,body,{headers:header});
  }

  /* user detail brochure */
  userDetailBrochure(){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    return this.http.get<any>(`${environment.apiUrl}/user-brochure`,{headers:header});
  }

  /* partner detail brochure */
  partnerDetailBrochure(){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    return this.http.get<any>(`${environment.apiUrl}/partner-brochure`,{headers:header});
  }

  /* lease list */
  leaseList(){
    let header=undefined;
    if(this.authGuard.getLoginToken()){
      header={
        Authorization: 'Token '+this.authGuard.getLoginToken()
      }
    }
    return this.http.get<any>(`${environment.apiUrl}/axl-store-lease`,{headers:header});
  }


}
