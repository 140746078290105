import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-career',
  templateUrl: './page-career.component.html',
  styleUrls: ['./page-career.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageCareerComponent implements OnInit  {
  environment=environment;

  page: number = 1;
  has_next: number = 0;
  toggleItem: any = "";
  requestSuccess:boolean =false;

  careers:{
    id: number;
    title: string;
    logo: string;
    description: string;
    rows: {title:string,description:string}[];
  }[]=[];
  
  constructor(private dataService:DataService,private activeRoute: ActivatedRoute){
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loadCareers();
    });
  }

  loadCareers(){
    this.dataService.careerList().subscribe((data:any)=>{
      if(data.success){
        this.requestSuccess=true;
        this.careers = this.careers.concat(data.careers);
        this.page = data.page;
        this.has_next = data.has_next;
      }
    });
  }

  readMoreToggle(id:any) :void{
    if(this.toggleItem!=id) {
      this.toggleItem = id;
    } else {
      this.toggleItem = null;
    }
  }

  loadMore() :void {
    if (this.has_next) {
      this.page++;
      this.loadCareers();
    }
  }

}
