import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() images: string[] = [];
  currentIndex: number = 0;
  intervalId: any;
  environment = environment;

  ngOnInit(): void {
    if (this.images.length > 0) {
      this.startAutoSlide();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images'] && this.images.length > 0) {
      this.startAutoSlide();
    }
  }

  startAutoSlide() {
    this.clearAutoSlide();

    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 3000); // Change the slide every 5 seconds
  }

  clearAutoSlide() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.resetAutoSlide();
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.resetAutoSlide();
  }

  ngOnDestroy() {
    this.clearAutoSlide();
  }

  isActive(index: number): boolean {
    return this.currentIndex === index;
  }

  goToSlide(index: number) {
    this.currentIndex = index;
    this.resetAutoSlide();
  }

  resetAutoSlide() {
    this.clearAutoSlide();
    this.startAutoSlide();
  }

  pauseAutoSlide() {
    this.clearAutoSlide();
  }

  resumeAutoSlide() {
    this.startAutoSlide();
  }
}
