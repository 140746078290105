import { CommonModule } from '@angular/common'; // ✅ Import CommonModule
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';
import { LocalStorageService } from '../service-storage/local-storage.service';
import { AuthGuard } from '../service-guard/auth.guard';
import { Router } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';

@Component({
  selector: 'app-page-product-registration',
  templateUrl: './page-product-registration.component.html',
  styleUrls: ['./page-product-registration.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxCaptchaModule],
})
export class PageProductRegistrationComponent implements OnInit {
  isUpdating: boolean = false;
  success_msg: any = null;
  error_msg: any = null;
  isFormSubmitted: boolean = false;
  products$ = this.dataService.storeList(); // Fetch products
  products: any[] = [];

  formGroup: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phone: ['', Validators.required],
    email: ['', [Validators.required,Validators.email]],
    address: ['', Validators.required],
    city: ['', Validators.required],
    state: ['', Validators.required],
    postalCode: ['', Validators.required],
    country: ['', Validators.required],
    product: ['', Validators.required],
    productId: ['', Validators.required],
    agreeToTerms: [false, Validators.requiredTrue], // Updated for proper validation
    marketingConsent: [false],
    personalData: [false ,Validators.requiredTrue],
    recaptcha: ['',Validators.required]
  });

  
  @ViewChild('captchaElemPopup') captchaElemPopup: any;
  recaptchaKey: any = environment.recaptchaSiteKey;
  public theme: any = 'dark';
  public size: any = 'normal';
  public lang = 'en';
  public type: any = 'image';

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private authGuard: AuthGuard,
    private router: Router,

    private cdr: ChangeDetectorRef
  ) {
    this.products$ = this.dataService.storeList(); // ✅ Assign observable here
  }
  trackByFn(index: number, item: any): string {
    return item.slug; // ✅ Use 'slug' as a unique identifier
  }
  ngOnInit(): void {
    this.loadProducts();    
    this.products$.subscribe((data) => {
      if (data && data.products) {
        this.products = data.products;
 // ✅ Debugging: Check if data is received
      } else {
        console.warn('No products found in data:', data);
      }
    });
    if (this.authGuard.isLoggedin()) {
      this.isUpdating = true;
      this.fillForm();
    } else {
      this.formGroup.patchValue({
        email: this.authGuard.getRegistrationEmail()
      });
    }
    this.formGroup.valueChanges.subscribe(values => {
      console.log('Form Values Changed:', values);
    });
  }

  loadProducts() {
    this.dataService.storeList().subscribe(
      (data: any) => {
        if (data.success) {
          this.products = data.products;
          this.cdr.detectChanges(); // Force UI update
        }
      },
      (error) => {
        console.error('Error fetching products:', error);
      }
    );
  }

  fillForm() {
    this.dataService.userProfileGet().subscribe(
      (data: any) => {
        if (data.success) {
          this.formGroup.patchValue({
            firstName: data.User.first_name,
            lastName: data.User.last_name,
            email: data.User.email,
            phone: data.User.phone_number,
            address: data.User.address,
            city: data.User.city,
            state: data.User.state,
            postalCode: data.User.postalCode,
            country: data.User.country,
            product: data.User.product,
            productId: data.User.productId,
            agreeToTerms: data.User.agreeToTerms,
            marketingConsent: data.User.marketingConsent,
            personalData: data.User.personalData,
            recaptcha: data.User.recaptcha,

          });
          this.success_msg = data.msg;
          this.isFormSubmitted = false;
          this.formGroup.reset();
          setTimeout(() => {
            this.success_msg = null;
          }, 5000);
        } else {
          this.error_msg = data.msg;
          setTimeout(() => {
            this.error_msg = null;
          }, 5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(() => {
          this.error_msg = null;
        }, 5000);
      }
    );
  }

  recaptchaSuccess(data: any) {
    this.formGroup.patchValue({ recaptcha: data });
  }

  onFormSubmit() {
    this.isFormSubmitted = true;
    if (this.formGroup.invalid) {

      return;
    }


    this.dataService
      .warrantyRegister(
        this.formGroup.get('firstName')?.value,
        this.formGroup.get('lastName')?.value,
        this.formGroup.get('email')?.value,
        this.formGroup.get('address')?.value,
        this.formGroup.get('phone')?.value,
        this.formGroup.get('city')?.value,
        this.formGroup.get('state')?.value,
        this.formGroup.get('postalCode')?.value,
        this.formGroup.get('country')?.value,
        this.formGroup.get('product')?.value,
        this.formGroup.get('productId')?.value,
        this.formGroup.get('agreeToTerms')?.value ? 'Y' : 'N',
        this.formGroup.get('marketingConsent')?.value ? 'Y' : 'N',
        this.formGroup.get('personalData')?.value ? 'Y' : 'N',
        this.formGroup.get('recaptcha')?.value
      )
      .subscribe(
        (data: any) => {
          this.success_msg = "Registration Successfully done!"
          if (data.success) {
        
            if (!this.isUpdating) {
              if (data.token) {
                this.authGuard.setUserLogin(data.name, data.token);
                let redirectURL = localStorage.getItem('axl_login_redirect');
                localStorage.removeItem('axl_login_redirect');

                if (redirectURL) {
                  this.router.navigate([redirectURL]);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }
            }
            this.success_msg = data.msg;
            this.isFormSubmitted = false;
            this.formGroup.reset();
            setTimeout(() => {
              this.success_msg = null;
            }, 5000);
          } else {
            this.error_msg = data.msg;
            setTimeout(() => {
              this.error_msg = null;
            }, 5000);
          }
        },
        (error: any) => {
          this.error_msg = error.error.msg;
          setTimeout(() => {
            this.error_msg = null;
          }, 5000);
        }
      );
  }
}
