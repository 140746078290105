import { AfterViewInit, Component, ElementRef, OnInit, ViewChild ,Renderer2} from '@angular/core';
import { DataService } from '../service-api/data.service';
import { environment } from 'src/environments/environment';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements AfterViewInit, OnInit {
  environment=environment;
  showModal = false; // Initialize modal to be hidden by default
  loading = true; // Initialize loader to be visible by default
  selectedImage: string = '';
  advimages: string[] = [];
  mobileVideo:string ='';
  innovations:{
    id: number;
    title: string;
    slug: string;
    homepage_video_file: string;
  }[]=[];
  newsFeeds:string='';
  carouselImages: string[] = [];
  storeItems:{
    id: number;
    title: string;
    slug: string;
    image: string;
  }[]=[];

  // playlistSrc=[
  //   'https://cms-dev.axldrone.com/attachments/drone_video/Agriculture_.mp4',
  //   'https://cms-dev.axldrone.com/attachments/drone_video/Relife_Operations.mp4'
  // ];
  firstPlay=true;
  holdNewLoad=false;
  playlistSrc:string[]=[];
  preloadIndex = 0;
  player1Src = '';
  player2Src = '';
  player3Src = '';
  player4Src = '';
  player1Loaded = false;
  player2Loaded = false;
  player3Loaded = false;
  player4Loaded = false;
  player1Playing = false;
  player2Playing = false;
  player3Playing = false;
  player4Playing = false;
  @ViewChild('videoPlayer1') videoPlayer1!: ElementRef;
  @ViewChild('videoPlayer2') videoPlayer2!: ElementRef;
  @ViewChild('videoPlayer3') videoPlayer3!: ElementRef;
  @ViewChild('videoPlayer4') videoPlayer4!: ElementRef;

  constructor(private dataService:DataService,private renderer: Renderer2, private el: ElementRef){
  }

    // -------------------- video -----------------
  ngAfterViewInit(): void {
    // this.videoPlayer1.nativeElement.onloadeddata = () => {
    //   if(this.firstPlay){
    //     this.videoPlayer1.nativeElement.play();
    //     this.firstPlay=false;
    //   }
    // }
    this.updateClass();
    window.addEventListener('resize', this.onResize.bind(this));
    this.videoPlayer1.nativeElement.oncanplaythrough = () => {
      this.player1Loaded = true;
      if(this.firstPlay){
        this.videoPlayer1.nativeElement.play();
        this.firstPlay=false;
      }
      if(this.player2Loaded==false && this.player2Src==''){
        this.player2Src = this.playlistSrc[1];
      }
    };

    this.videoPlayer1.nativeElement.onerror = async() => {
      // this.player1Loaded = false;
    };

    this.videoPlayer1.nativeElement.onplay = () => {
      this.player1Playing=true;
    };

    this.videoPlayer1.nativeElement.ontimeupdate = async() => {
      if(this.videoPlayer1.nativeElement.duration-this.videoPlayer1.nativeElement.currentTime <0.5){
        if(this.player2Loaded==true){
          this.videoPlayer2.nativeElement.play();
        }else{
          this.videoPlayer1.nativeElement.play();
          this.player1Playing=true;
        }  
      }
    }

    this.videoPlayer1.nativeElement.onended = async() => {
      this.player1Playing=false;
    };

    this.videoPlayer2.nativeElement.oncanplaythrough = () => {
      this.player2Loaded = true;
      if(this.player3Loaded==false && this.player3Src==''){
        this.player3Src = this.playlistSrc[2];
      }
    };

    this.videoPlayer2.nativeElement.onerror = async() => {
      // this.player2Loaded = false;
    };

    this.videoPlayer2.nativeElement.onplay = () => {
      this.player2Playing=true;
    };

    this.videoPlayer2.nativeElement.ontimeupdate = async() => {
      if(this.videoPlayer2.nativeElement.duration-this.videoPlayer2.nativeElement.currentTime <0.5){
        if(this.player3Loaded==true){
          this.videoPlayer3.nativeElement.play();
        }else{
          this.videoPlayer1.nativeElement.play();
          this.player1Playing=true;
        } 
      }
    }

    this.videoPlayer2.nativeElement.onended = () => {
      this.player2Playing=false;
    };

    this.videoPlayer3.nativeElement.oncanplaythrough = () => {
      this.player3Loaded = true;
      if(this.player4Loaded==false && this.player4Src==''){
        this.player4Src = this.playlistSrc[3];
      }
    };

    this.videoPlayer3.nativeElement.onerror = async() => {
      // this.player3Loaded = false;
    };

    this.videoPlayer3.nativeElement.onplay = () => {
      this.player3Playing=true;
      
    };

    this.videoPlayer3.nativeElement.ontimeupdate = async() => {
      if(this.videoPlayer3.nativeElement.duration-this.videoPlayer3.nativeElement.currentTime <0.5){
        if(this.player4Loaded==true){
          this.videoPlayer4.nativeElement.play();
        }else{
          this.videoPlayer1.nativeElement.play();
          this.player1Playing=true;
        } 
      }
    }

    this.videoPlayer3.nativeElement.onended = () => {
      this.player3Playing=false;
    };

    this.videoPlayer4.nativeElement.oncanplaythrough = () => {
      this.player4Loaded = true;
    };

    this.videoPlayer4.nativeElement.onerror = async() => {
      // this.player4Loaded = false;
    };

    this.videoPlayer4.nativeElement.onplay = () => {
      this.player4Playing=true;
    };

    this.videoPlayer4.nativeElement.ontimeupdate = async() => {
      if(this.videoPlayer4.nativeElement.duration-this.videoPlayer4.nativeElement.currentTime <0.5){
        if(this.player1Loaded==true){
          this.videoPlayer1.nativeElement.play();
        }else{
          this.videoPlayer1.nativeElement.play();
          this.player1Playing=true;
        } 
      }
    }

    this.videoPlayer4.nativeElement.onended = () => {
      this.player4Playing=false;
      
    };
  }

  // -------------------- Innovation & Product ----------------
  ngOnInit(): void {

    this.loadAdvertisementList();
    this.loadInnovationList();
    this.loadHomeProduct();
    this.updateClass();
    // this.carouselImages=[
   
    
    
    //   '../../assets/image/ads5.png',
    //   '../../assets/image/ads6.png',
    
    // ]
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }
  
  onResize(event: Event): void {
    this.updateClass();
  }
  
  private updateClass(): void {
    const width = window.innerWidth;
    const bannerContentElement = this.el.nativeElement.querySelector('.banner-innovation-content');

    if (width < 1921) {
      this.renderer.addClass(bannerContentElement, 'container-home');
      this.renderer.removeClass(bannerContentElement, 'container');
    } else {
      this.renderer.addClass(bannerContentElement, 'container');
      this.renderer.removeClass(bannerContentElement, 'container-home');
    }
  }
  loadAdvertisementList(){
    this.dataService.advertisementList().subscribe((data:any)=>{
      if(data.success){
        this.newsFeeds=data.newsfeed;
        this .carouselImages=data.banner_list;
      }
    })
  }
 

  loadInnovationList(){
    this.dataService.innovationList().subscribe((data:any)=>{
      if(data.success){
        this.mobileVideo = environment.assetUrl+'/'+data.video;
        this.innovations = data.categories;
       
        
        this.playlistSrc=[];
        this.innovations.forEach((value)=>{
          this.playlistSrc.push(environment.assetUrl+'/'+value.homepage_video_file);
        });
        this.player1Src = this.playlistSrc[this.preloadIndex];        
      }
    });
    
    
    
  }
 
  loadHomeProduct(){
    this.dataService.storeList().subscribe((data:any)=>{
      if(data.success){
        this.storeItems = data.products.filter((subdata:any)=>subdata.set_for_homepage==='Y');
      }
    });
  }
 
}
