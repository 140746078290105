<div class="privacy">
    <div class="row mx-0 h-100 justify-content-center align-items-center">
      <div class="col-md-12 col-lg-7 col-xl-6 text-center banner">
        <h1 data-aos="fade-up" data-aos-duration="1000">Refund Policy</h1>
      </div>
    </div>
</div>
  <!--Banner End-->
  
  <!--Section Category Start-->
  <div class="privacy_body">
    <div class="container">

        <!-- <h4>Refund Policy</h4> -->
        <p>All purchases made through our App, including subscriptions, in-app purchases, and one-time purchases, are final and non-refundable. We do not offer refunds or credits for any partially used subscription periods or unused features of the App.</p>
        <p><strong>Exceptions:</strong></p>
        <p>Refunds will only be provided in the following circumstances:</p>
        <ul>
            <li>Duplicate Purchases: If you accidentally purchase the same item or service more than once, please contact us promptly for assistance.</li>
        </ul>
        <p><strong>Contact Us:</strong>
        If you have any questions about our refund policy or need assistance with a duplicate purchase, please contact our customer support team at <a href="mailto:info@axldrone.com">info&#64;axldrone.com</a>. We are here to help resolve any issues you may encounter.</p>
    </div>
  </div>
