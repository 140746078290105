import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';
import { LocalStorageService } from '../service-storage/local-storage.service';
import { AuthGuard } from '../service-guard/auth.guard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-user-profile',
  templateUrl: './page-user-profile.component.html',
  styleUrls: ['./page-user-profile.component.scss']
})
export class PageUserProfileComponent implements OnInit {
// isPasswordHidden:boolean=true;
isUpdating:boolean=false;

success_msg: any = null;
error_msg: any = null;
isFormSubmitted: boolean = false;

//   controlOptions: AbstractControlOptions = {
//     validators: this.customValidationFunction
//  }
formGroup:FormGroup = this.formBuilder.group({
  firstName: ['',Validators.required],
  lastName: ['',Validators.required],
  email: ['',[Validators.required,Validators.email]],
  phoneCountryCode: ['0'],
  phone: ['',Validators.required],
  profession: ['',Validators.required],
  usage: ['',Validators.required]
});
// },this.controlOptions);

  constructor(private formBuilder:FormBuilder,private dataService:DataService,private localStorageService:LocalStorageService,private authGuard:AuthGuard,private router:Router){}
  ngOnInit(): void {
    
    if(this.authGuard.isLoggedin()){
      this.isUpdating=true;
      this.fillForm();
    }else{
      this.formGroup.patchValue({
        email: this.authGuard.getRegistrationEmail()
      });
    }
  }

// customValidationFunction(control: AbstractControl): ValidationErrors | null {
//   let emailField = control.get('email')?.value;
//   let phoneField = control.get('phone')?.value;
//   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
// }

fillForm(){
    this.dataService.userProfileGet(
    ).subscribe((data:any)=>{
      if(data.success){
        this.formGroup.setValue({
          firstName: data.User.first_name,
          lastName: data.User.last_name,
          email: data.User.email,
          phoneCountryCode: data.User.phone_country_code,
          phone: data.User.phone_number,
          profession: data.User.profession,
          usage: data.User.drone_usage_area
        });
        this.success_msg = data.msg;
        this.isFormSubmitted = false;
        this.formGroup.reset();
        setTimeout(()=>{
          this.success_msg = null;
        },5000);
      }else{
        this.error_msg = data.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }
    },
    (error: any) => {
      this.error_msg = error.error.msg;
      setTimeout(()=>{
        this.error_msg = null;
      },5000);
    }    
    );
}

onFormSubmit(){
  this.isFormSubmitted =true;
  if(this.formGroup.invalid){
    return;
  }
  this.dataService.userProfileSet(
    this.formGroup.get('firstName')?.value,
    this.formGroup.get('lastName')?.value,
    this.formGroup.get('email')?.value,
    this.formGroup.get('phoneCountryCode')?.value,
    this.formGroup.get('phone')?.value,
    this.formGroup.get('profession')?.value,
    this.formGroup.get('usage')?.value
    ).subscribe((data:any)=>{
      if(data.success){
        if(!this.isUpdating){
          if(data.token){
            this.authGuard.setUserLogin(data.name,data.token);
            let redirectURL = localStorage.getItem('axl_login_redirect');
            localStorage.removeItem('axl_login_redirect');
            
            if (redirectURL) {
              this.router.navigate([redirectURL]);
            }else{
              this.router.navigate(['/dashboard']);
            }
          }
        }
        this.success_msg = data.msg;
        this.isFormSubmitted = false;
        setTimeout(()=>{
          this.success_msg = null;
        },5000);
      }else{
        this.error_msg = data.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }
    },
    (error: any) => {
      this.error_msg = error.error.msg;
      setTimeout(()=>{
        this.error_msg = null;
      },5000);
    }    
    );
}

// passwordHiddenToggle(){
//   this.isPasswordHidden = !this.isPasswordHidden;
// }
}
