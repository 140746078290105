<section class="newsevent">
    <div class="container-fluid">
        <div class="row flex">
            
            <!-- side menu -->
            <div class="col-md-2 px-0 sidebar order-last">
                <div class="text-center my-2 sidebar-head">More News and Events</div>
                <div>
                    
                <!-- <div id="sidebar" class="collapse collapse-horizontal show border-end"> -->
                    <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start">
                        <div *ngFor="let item of items">
                            <a [routerLink]="['/news-and-events',item.slug]" style="text-decoration: none;">
                                <div class="card ne-card" >
                                    <img class="card-img" src="{{environment.assetUrl}}/{{item.thumbnail}}" alt="{{item.title}}">
                                    <!-- <div class="card-body">
                                    </div>  -->
                                    <p class="card-title text-center">{{item.title}}</p>
                                </div>
                            </a>
                        </div> 
                    </div>
                    
                </div>
                <!-- </div> -->
            </div>

            <!-- main panel -->
            <main class="col-md-10 ps-md-2 mainpanel order-first">

                <!-- breadcrumb -->
                <!-- <div class="hstack pb-4">
                    <i class="bi bi-chevron-double-right bi-lg py-2 p-1"></i>
                    <div class="hstack gap-1">
                        <a [routerLink]="['/news-and-events']">News and Events</a> 
                        <div *ngIf="activeSlug!=''">
                            <span>/</span>
                            {{activeItemDetail.title}}
                        </div>
                    </div>
                </div> -->

                <!-- without slug -->
                <!-- redirected to first slug with skipLocationChange-->

                <!-- with slug -->
                <div class="item-detail" *ngIf="activeSlug!=''">
                    <!-- <div>{{activeItemDetail.date}}</div>
                    <div>{{activeItemDetail.title}}</div>
                    <div>{{activeItemDetail.description}}</div>
                    <div *ngFor="let media of activeItemDetail.gallery">
                        <div *ngIf="media.file_type=='image'">
                            <img [src]="environment.assetUrl+'/'+media.file" alt="{{media.slug}}">
                        </div>
                        <div *ngIf="media.file_type=='video'">
                            <video [src]="environment.assetUrl+'/'+media.file" [muted]="'muted'" poster="../../assets/image/home_video_poster.jpg"></video>
                        </div>
                    </div>
                     -->
                     <ngb-carousel
                     #carousel
                     [interval]="5000"
                     [pauseOnHover]="pauseOnHover"
                     [pauseOnFocus]="pauseOnFocus"
                     (slide)="onSlide($event)"
                 >
                     @for (media of activeItemDetail.gallery; track media; let i = $index) {
                         <ng-template ngbSlide>
                             <div class="carousel-caption" style="background:rgba(0, 0, 0, 0.7);">
                                 <h6>{{activeItemDetail.date}}</h6>
                                 <h3>{{activeItemDetail.title}}</h3>
                                 <p>{{activeItemDetail.description}}</p>
                             </div>
                             <!-- <a href="https://www.google.fr/?q=Number+{{ i + 1 }}" target="_blank" rel="nofollow noopener noreferrer">
                                 <div class="picsum-img-wrapper">
                                     <img [src]="img" alt="My image {{ i + 1 }} description" />
                                 </div>
                             </a> -->
                            <div class="text-center centered-element" *ngIf="media.file_type=='image'" style="width: auto;height: 86vh;display: flex;
                            align-items: center;
                            justify-content: center;">
                                <!-- <img style="width: 80vh;height: auto;overflow: hidden;" [src]="environment.assetUrl+'/'+media.file" alt="{{media.slug}}"> -->
                                <img style="max-height:100%; max-width:100%;" [src]="environment.assetUrl+'/'+media.file" alt="{{media.slug}}">
                            </div>
                            <div *ngIf="media.file_type=='video'">
                                <!-- <video style="width: 80vh;height: auto;overflow: hidden;" [src]="environment.assetUrl+'/'+media.file" autoplay [muted]="'muted'" poster="../../assets/image/home_video_poster.jpg"></video> -->
                                <video style="width: 100%;height: 86vh;" [src]="environment.assetUrl+'/'+media.file" autoplay loop [muted]="'muted'" poster="../../assets/image/axldrone-loading.png"></video>
                            </div>
                         </ng-template>
                     }
                 </ngb-carousel>

                    
                    
                </div>
            </main>

            
    

        </div>
    </div>
  </section>
  