import { Component, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';
import { LocalStorageService } from '../service-storage/local-storage.service';


@Component({
  selector: 'app-page-user-login',
  templateUrl: './page-user-login.component.html',
  styleUrls: ['./page-user-login.component.scss']
})
export class PageUserLoginComponent {
// isPasswordHidden:boolean=true;
public isSuccess: boolean =false;
email:string ='';

success_msg: any = null;
error_msg: any = null;
isFormSubmitted: boolean = false;
isLoading: boolean = false;
//   controlOptions: AbstractControlOptions = {
//     validators: this.customValidationFunction
//  }
formGroup:FormGroup = this.formBuilder.group({
  email: ['',[Validators.required,Validators.email]]
});

 


// Custom method to log variables

// },this.controlOptions);

constructor(private formBuilder:FormBuilder,private dataService:DataService,private localStorageService:LocalStorageService){
   // Log variables in the constructor

 
}

// customValidationFunction(control: AbstractControl): ValidationErrors | null {
//   let emailField = control.get('email')?.value;
//   let phoneField = control.get('phone')?.value;
//   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
// }

onFormSubmit(){
  
  this.isFormSubmitted =true;
  if(this.formGroup.invalid){
    return;
  }
  this.isLoading = true;
  this.dataService.userOtpSend(
    this.formGroup.get('email')?.value
    ).subscribe((data:any)=>{
      if(data.success){
        this.isLoading = false;
        this.isSuccess=true;
        this.email = this.formGroup.get('email')?.value;
        this.success_msg = data.msg;
     
        this.formGroup.reset();
        this.isFormSubmitted = false;
        setTimeout(()=>{
          this.success_msg = null;
        },5000);
      }else{
        this.error_msg = data.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }
    },
    (error: any) => {
      this.isLoading = false;
      this.error_msg = error.error.msg;
      setTimeout(()=>{
        this.error_msg = null;
      },5000);
    }    
    );
}

// passwordHiddenToggle(){
//   this.isPasswordHidden = !this.isPasswordHidden;
// }
}
