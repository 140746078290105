import { Component, OnInit, ViewEncapsulation,Renderer2,NgModule } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-page-product-detail',
  templateUrl: './page-product-detail.component.html',
  styleUrls: ['./page-product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PageProductDetailComponent implements OnInit {
  environment=environment;

  activeImage: any = '';

  product:{
    id: number,
    title: string,
    slug: string,
    description: string,
    image: string,
    sec_img: string,
    third_img: string,
    designed_for: {icon:string,title:string}[],
    specification: {name:string,value:string,additional_hardware:string}[],
    to_lease: string,
    types: string,
    amazon_url: string,
    gem_url:string,
    feature: string,
    size: string,
    brochure_file:string,
    payload:any,
    payload_images: { payload_img: string }[];
  }={id:0,title:'',slug:'',description:'',image:'',sec_img:'',third_img:'',designed_for:[],specification:[],to_lease:'',types:'',amazon_url:'',gem_url:'',feature:'',size:'',brochure_file:'',payload:'',payload_images:[]};

  constructor(private dataService:DataService,private activeRoute: ActivatedRoute,private router: Router,private renderer: Renderer2,){
  }
   // Get unique features
   getUniqueFeatures(): string[] {
    if (!this.product.specification) return [];
    return [...new Set(this.product.specification.map(item => item.additional_hardware))];
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loadProduct(params['slug']);
    });
  }

  loadProduct(slug:string){
    if(slug){
      this.dataService.storeItemDetail(slug).subscribe((data:any)=>{
        if(data.success){
            this.product=data.products;
            this.setActiveImageOnClick(this.product.image);
        }
        else{
          this.router.navigate(['/404'],{skipLocationChange: true});
        }
      },(error: any)=>{
        this.router.navigate(['/404'],{skipLocationChange: true});
      });
    }
  }

  setActiveImageOnClick(path:string){
    this.activeImage = environment.assetUrl+'/'+path;
  }
  
  
  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.assetUrl+'/'+this.product.brochure_file);
    //link.setAttribute('download', `axldrone_general_brochure.pdf`);
    link.click();
    link.remove();
  }
}
