import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-innovation',
  templateUrl: './page-innovation.component.html',
  styleUrls: ['./page-innovation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageInnovationComponent implements OnInit {
  environment=environment;

  innovation:{
    id: number;
    title: string;
    slug: string;
    description: string;
    video_file: string;
    image: string;
    brochure_file: string;
  }={id:0,title:'',slug:'',description:'',video_file:'',brochure_file:'',image:''};
  
  innovationProducts:{
    id: number;
    title: string;
    slug: string;
    description: string;
    video_file: string;
    image: string;
    brochure_file: string;
  }[]=[];

  constructor(private dataService:DataService,private activeRoute: ActivatedRoute,private renderer: Renderer2,private router: Router){
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loadInnovation(params['slug']);
      this.loadInnovationProducts(params['slug']);
    });
  }

  loadInnovation(slug:string){
    if(slug){
      this.dataService.innovationDetail(slug).subscribe((data:any)=>{
        if(data.success){
          this.innovation=data.categories;
        }
        else{
          this.router.navigate(['/404'],{skipLocationChange: true});
        }
      },(error: any)=>{
        this.router.navigate(['/404'],{skipLocationChange: true});
      });
    }
  }

  loadInnovationProducts(slug:string){
    if(slug){
      this.dataService.innovationProductList(slug).subscribe((data:any)=>{
        if(data.success){
          this.innovationProducts=data.products;
        }
      });
    }
  }

  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.assetUrl+'/'+this.innovation.brochure_file);
    //link.setAttribute('download', `axldrone_general_brochure.pdf`);
    link.click();
    link.remove();
  }

}
