import { Component } from '@angular/core';

@Component({
  selector: 'app-page-shippingpolicy',
  
  templateUrl: './page-shippingpolicy.component.html',
  styleUrl: './page-shippingpolicy.component.scss'
})
export class PageShippingpolicyComponent {

}
