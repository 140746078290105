import { Component } from '@angular/core';

@Component({
  selector: 'app-page-refundpolicy',

  templateUrl: './page-refundpolicy.component.html',
  styleUrl: './page-refundpolicy.component.scss'
})
export class PageRefundpolicyComponent {

}
