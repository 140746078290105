import { Component, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DataService } from '../service-api/data.service';
import { Router } from '@angular/router';
import { AuthGuard } from '../service-guard/auth.guard';


@Component({
  selector: 'app-page-partner-login',
  templateUrl: './page-partner-login.component.html',
  styleUrls: ['./page-partner-login.component.scss']
})
export class PagePartnerLoginComponent {
  // isPasswordHidden:boolean=true;

  success_msg: any = null;
  error_msg: any = null;
  isFormSubmitted: boolean = false;
  
//   controlOptions: AbstractControlOptions = {
//     validators: this.customValidationFunction
//  }
  formGroup:FormGroup = this.formBuilder.group({
    username: ['',Validators.required],
    password:['',Validators.required]
  });
// },this.controlOptions);

  constructor(private formBuilder:FormBuilder,private dataService:DataService, private router:Router,private authGuard: AuthGuard){}

  recaptchaSuccess(data:any) {
  }

  // customValidationFunction(control: AbstractControl): ValidationErrors | null {
  //   let emailField = control.get('email')?.value;
  //   let phoneField = control.get('phone')?.value;
  //   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
  // }

  onFormSubmit(){
    this.isFormSubmitted =true;
    if(this.formGroup.invalid){
      return;
    }
    this.dataService.partnerLogin(
      this.formGroup.get('username')?.value,
      this.formGroup.get('password')?.value
      ).subscribe((data:any)=>{
        if(data.success){
          if(data.token){
            this.authGuard.setPartnerLogin(data.name,data.token)
            this.router.navigate(['/partner/dashboard']);
          }
          else{
            this.router.navigate(['']);
          }
          this.success_msg = data.msg;
          this.isFormSubmitted = false;
          this.formGroup.reset();
          setTimeout(()=>{
            this.success_msg = null;
          },5000);
        }else{
          this.error_msg = data.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }    
      );
  }

  // passwordHiddenToggle(){
  //   this.isPasswordHidden = !this.isPasswordHidden;
  // }
}
