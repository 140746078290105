import { Component, OnInit,HostListener } from '@angular/core';
import { DataService} from '../service-api/data.service';
import { LocalService } from '../service-storage/local.service';
import { AuthGuard } from '../service-guard/auth.guard';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {
  menuOpen = false;
  newsEventsEnabled:boolean = false;

  innovations:{
    id: number;
    title: string;
    slug: string;
  }[]=[];

  coes:{
    id: number;
    name: string;
    slug: string;
  }[]=[];

  constructor(private dataService:DataService,protected localService:LocalService,public authGuard:AuthGuard){}
  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }
  ngOnInit(): void {
    this.loadInnovation();
    this.loadCoe();
    this.checkNewsEvents();
  }

  loadInnovation(){
    this.dataService.innovationList().subscribe((data:any)=>{
      if(data.success){
        this.innovations = data.categories;
      }
    });
  }

  loadCoe(){
    this.dataService.droneCoeList().subscribe((data:any)=>{
      if(data.success){
        this.coes = data.coes;
      }
    });
  }

  checkNewsEvents(){
    this.dataService.newsEventList().subscribe((data:any)=>{
      if(data.success){
        if(data.news_event_data.length!=0){
          this.newsEventsEnabled=true;
        }
      }
    });
  }
  @HostListener('document:click', ['$event'])
  closeMenuOnClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    const navbar = document.querySelector('.navbar-collapse');
    const toggler = document.querySelector('.navbar-toggler');

    if (navbar && !navbar.contains(target) && toggler && !toggler.contains(target)) {
      this.menuOpen = false; // Close menu if clicked outside navbar and toggler
    }
  }

  onItemClick(): void {
    this.menuOpen = false; // Close menu on item click
  }
}
