

<section>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6 side-view p-0 d-none d-lg-block">
                <img src="../../assets/image/login_left.png" alt="Let's Talk" class="img-fluid" width="100%" >
            </div>

            <div class="col-lg-6 login-view p-5">
                <div class="container px-5">
                    <h2 class="pt-5">Profile Update
                    </h2>

                    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" style="padding-top: 4rem;">
                        <div class="form-group">
                            <label>First Name</label>
                            <input [readOnly]="isUpdating" type="text" autocomplete="off" formControlName="firstName" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('firstName')?.errors}" class="form-control" placeholder="Your given name">
                        </div>
                        <div class="form-group">
                            <label>Surname</label>
                            <input [readOnly]="isUpdating" type="text" autocomplete="off" formControlName="lastName" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('lastName')?.errors}" class="form-control" placeholder="How may we address you?">
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input readonly type="text" autocomplete="off" formControlName="email" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('email')?.errors}" class="form-control" placeholder="Your email">
                        </div>
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="text" autocomplete="off" formControlName="phone" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('phone')?.errors}" class="form-control" placeholder="Phone number by which we can reach you">
                        </div>
                        <div class="form-group">
                            <label>Profession</label>
                            <input type="text" autocomplete="off" formControlName="profession" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('profession')?.errors}" class="form-control" placeholder="What line of work are you in?">
                        </div>
                        <div class="form-group">
                            <label>DRONE USAGE AREA</label>
                            <input type="text" autocomplete="off" formControlName="usage" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('usage')?.errors}" class="form-control" placeholder="In which areas do you plan to use the drone? (Eg: cinema, agriculture, ...)">
                        </div>
                        <div class="text-right">
                            <span class="alert in success-green" *ngIf="success_msg">
                                {{success_msg}}
                            </span>
                            <span class="alert in error-red" *ngIf="error_msg">
                                {{error_msg}}
                            </span>
                            <button [disabled]="!(success_msg===null && error_msg===null)" type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem; width: 6rem;">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
                

            </div>

        </div>
    </div>
</section>
