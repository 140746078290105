<section>
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6 p-0 d-none d-lg-block">
                <img height="100%" src="../../assets/image/lets-talk_side.png" alt="Let's Talk" width="100%" >
            </div>

            <div class="col-lg-6 px-5 py-4">
                <h4 class="pb-3" style="color: #F97738;">Get in Touch</h4>
                <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
                    <div class="form-group">
                        <label>First Name</label>
                        <input type="text" autocomplete="off" formControlName="firstName" [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('firstName')?.errors}" class="form-control" placeholder="Enter your first name">
                    </div>
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" autocomplete="off" formControlName="lastName"  [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('lastName')?.errors}" class="form-control" placeholder="Enter your last name">
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" autocomplete="off" formControlName="email"  [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('email')?.errors}" class="form-control" placeholder="Enter your email">
                    </div>
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" autocomplete="off" formControlName="phone"  [ngClass]="{'has_error' : isFormSubmitted && formGroup.get('phone')?.errors}" class="form-control" placeholder="Enter your phone">
                    </div>
                    <div class="form-group">
                        <label>Message</label>
                        <input type="text" autocomplete="off" formControlName="message" class="form-control" placeholder="Enter your message">
                    </div>
                    <div class="form-group">
                        <ngx-recaptcha2 #captchaElemPopup siteKey="{{recaptchaKey}}" (success)="recaptchaSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha"></ngx-recaptcha2>
                    </div>
                    <div class="text-right">
                        <span class="alert in success-green" *ngIf="success_msg">
                            {{success_msg}}
                        </span>
                        <span class="alert in error-red" *ngIf="error_msg">
                            {{error_msg}}
                        </span>
                        <!-- <button type="submit" class="btn btn-secondary button-custom mt-5" [disabled]="!formGroup.valid" style="height: 2.5rem;"></button> -->
                        <button *ngIf="success_msg===null && error_msg===null" type="submit" class="btn btn-secondary button-custom mt-5" style="height: 2.5rem;">
                            Send Now
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.3301 3.6691C20.141 3.48127 19.9037 3.34922 19.6443 3.28748C19.385 3.22573 19.1136 3.23667 18.8601 3.3191L4.23012 8.1991C3.9588 8.28508 3.71903 8.44953 3.54111 8.6717C3.36319 8.89386 3.2551 9.16376 3.23049 9.44732C3.20588 9.73088 3.26585 10.0154 3.40284 10.2649C3.53982 10.5144 3.74766 10.7177 4.00012 10.8491L10.0701 13.8491L13.0701 19.9391C13.1907 20.1775 13.3752 20.3776 13.603 20.5171C13.8309 20.6566 14.093 20.73 14.3601 20.7291H14.4601C14.7462 20.708 15.0194 20.6014 15.2441 20.4231C15.4688 20.2447 15.6346 20.0029 15.7201 19.7291L20.6701 5.1391C20.7585 4.88704 20.7735 4.61505 20.7133 4.3548C20.6531 4.09455 20.5202 3.85676 20.3301 3.6691ZM4.85012 9.5791L17.6201 5.3191L10.5301 12.4091L4.85012 9.5791ZM14.4301 19.1491L11.5901 13.4691L18.6801 6.3791L14.4301 19.1491Z" fill="white"/>
                            </svg>
                        </button>
                    </div>

                </form>
            </div>

        </div>
    </div>
</section>
