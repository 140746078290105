import { Component ,Input,OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-payload',
  templateUrl: './product-payload.component.html',
  styleUrls: ['./product-payload.component.scss']
})
export class ProductPayloadComponent{
  // Add your component logic here
  @Input() payloadImages: { payload_img: string }[] = [];
  @Input() payload: any; // Adjust type based on the payload structure
  @Input() lease: any; // Adjust type based on the payload structure
  @Input() slug: any; // Adjust type based on the payload structure
  environment = environment;
constructor(private activeRoute: ActivatedRoute,private router: Router){}

  onBuyNow() {
    // Handle Buy Now action
  }

  onLease() {
    // Handle Lease action
  }
}
