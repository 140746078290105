<section class="axllease">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4>Lease a Drone</h4>
            </div>
            <div class="col-12" *ngFor="let leaseDrone of leaseDrones | keyvalue">
                <div class="row drone-card">
                <div class="col-12">
                    <h5>{{leaseDrone.value.title}}</h5>
                </div>
                <div class="col-md-3">
                    <img class="card-img-top" src="{{environment.assetUrl}}/{{leaseDrone.value.image}}" alt="{{leaseDrone.value.title}}" style="border-radius: 1rem;border-color: #F97738;">
                </div>
                <div class="col-md-5">
                    <div class="vstack gap-4">
                        <h6 class="payload-label">Payloads</h6>
                        <div class="row" *ngFor="let payload of leaseDrone.value.payloads">
                            <div class="col-9">
                                {{payload.name}}
                            </div>
                            <div class="col-3">

                                <!-- <input type="checkbox" [(ngModel)]="payload.checked"> -->
                                <div class="checkbox-wrapper">
                                    <label for="{{leaseDrone.key+'-'+payload.name}}">
                                      <input type="checkbox" id="{{leaseDrone.key+'-'+payload.name}}" [(ngModel)]="payload.checked">
                                      <span class="cbx">
                                        <svg width="12px" height="11px" viewBox="0 0 12 11">
                                          <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                                        </svg>
                                      </span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 vstack gap-2">
                    <div class="row">
                        <div class="col-9">
                            Drone Pilot
                        </div>
                        <div class="col-3">

                            <!-- <input type="checkbox" [(ngModel)]="leaseDrone.value.pilotRequired"> -->
                            <div class="checkbox-wrapper">
                                <label for="cbk-pilot">
                                  <input type="checkbox" id="cbk-pilot" [(ngModel)]="leaseDrone.value.pilotRequired">
                                  <span class="cbx">
                                    <svg width="12px" height="11px" viewBox="0 0 12 11">
                                      <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                                    </svg>
                                  </span>
                                </label>
                            </div>
                            
                        </div>
                    </div>
                    <div><h6>Custom payloads if any:</h6></div>
                    <div style="height: 50%"><textarea name="Text1" style="height: 100%; width: 100%;" [(ngModel)]="leaseDrone.value.customPayload"></textarea></div>
                    <button *ngIf="leaseDrone.value.success_msg===null && leaseDrone.value.error_msg===null" (click)="getQuoteOnClick(leaseDrone.key)" class="btn btn-secondary button-custom lets-talk-expanded">Get Quote</button>
                    <div>
                        <span class="alert in success-green" style="padding: 0;" *ngIf="leaseDrone.value.success_msg">
                            {{leaseDrone.value.success_msg}}
                        </span>
                        <span class="alert in error-red" style="padding: 0;" *ngIf="leaseDrone.value.error_msg">
                            {{leaseDrone.value.error_msg}}
                        </span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>