import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EmailService } from '../service-api/email.service';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-page-coe-enquiry',
  templateUrl: './page-coe-enquiry.component.html',
  styleUrls: ['./page-coe-enquiry.component.scss']
})
export class PageCoeEnquiryComponent implements OnInit {
  coeSlug:string='';

  success_msg: any = null;
  error_msg: any = null;
  isFormSubmitted: boolean = false;

  @ViewChild('captchaElemPopup') captchaElemPopup: any;
  recaptchaKey: any = environment.recaptchaSiteKey;
  public theme:any= 'dark';
  public size:any= 'normal';
  public lang = 'en';
  public type:any= 'image';
  
//   controlOptions: AbstractControlOptions = {
//     validators: this.customValidationFunction
//  }
  formGroup:FormGroup = this.formBuilder.group({
    fullName: ['',Validators.required],
    email:['',Validators.email],
    phone:[''],
    message:[''],
    recaptcha:['',Validators.required]
  });
  
  environment=environment;
  
  coe:{
    id: number,
    name: string,
    slug: string,
    address: string,
    logo: string,
    video_file: string,
    image: string,
    secondary_image: string,
    description: string,
    coespecification__slug: {description:string}[],
  }={id:0,name:'',slug:'',address:'',logo:'',video_file:'',image:'',secondary_image:'',description:'',coespecification__slug:[]};

  constructor(private dataService:DataService,private activeRoute: ActivatedRoute,private formBuilder:FormBuilder,private emailService:EmailService){
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.coeSlug=params['slug'];
      this.loadCoe(this.coeSlug);
    });
  }

  loadCoe(slug:string){
    if(slug){
      this.dataService.droneCoeItemDetail(slug).subscribe((data:any)=>{
        if(data.success){
          this.coe=data.coes;
        }
      });
    }
  }

  recaptchaSuccess(data:any) {
  }

  // customValidationFunction(control: AbstractControl): ValidationErrors | null {
  //   let emailField = control.get('email')?.value;
  //   let phoneField = control.get('phone')?.value;
  //   return (emailField=='' && phoneField=='') ? { contactMissing:true } : null;
  // }

  onFormSubmit(){
    this.isFormSubmitted =true;
    if(this.formGroup.invalid){
      return;
    }
    this.emailService.coeEnquiry(
      this.coeSlug,
      this.formGroup.get('fullName')?.value,
      this.formGroup.get('email')?.value,
      this.formGroup.get('phone')?.value,
      this.formGroup.get('message')?.value,
      this.formGroup.get('recaptcha')?.value
      ).subscribe((data:any)=>{
        if(data.success){
          this.success_msg = data.msg;
          this.isFormSubmitted = false;
          this.captchaElemPopup.resetCaptcha();
          this.formGroup.reset();
          setTimeout(()=>{
            this.success_msg = null;
          },5000);
        }else{
          this.error_msg = data.msg;
          setTimeout(()=>{
            this.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.error_msg = error.error.msg;
        setTimeout(()=>{
          this.error_msg = null;
        },5000);
      }    
      );
  }
}
