import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges,ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popover-component',
  templateUrl: './popover-component.component.html',
  styleUrls: ['./popover-component.component.scss']
})
export class PopoverComponentComponent implements OnInit, OnChanges{
  @Input() showModal = false;
  @Input() loading = false;
  @Input() selectedImage: string = '';
  @Output() closeModal = new EventEmitter<void>();
  @Output() setLoading = new EventEmitter<boolean>();
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  environment = environment;
  imageLoaded: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.toggleMute();
    }, 3000);
  }

 

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedImage'] && changes['selectedImage'].currentValue) {
      this.imageLoaded = false; // Reset image loaded state
     
    }
  }
  
  toggleMute(): void {
    const video = this.videoPlayer.nativeElement;
    if (video.muted) {
      video.muted = false;
    } else {
      video.muted = true;
      video.play();
    }
  }
  // unmuteVideo(): void {
  //   if (this.videoPlayer && this.videoPlayer.nativeElement) {
  //     this.videoPlayer.nativeElement.muted = false;
  //   }
  // }

  onVideoLoad(): void {
    this.imageLoaded = true; // Mark video as loaded
    this.setLoading.emit(false); // Hide loader
    this.cdr.detectChanges(); // Ensure Angular detects the changes
  }

  isVideo(mediaUrl: string | null): boolean {
    return mediaUrl !== null && (mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.mov'));
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }

  onImageLoad(): void {
    this.imageLoaded = true;
    this.setLoading.emit(false);
    this.cdr.detectChanges();
  }

  onClickOutsideModal(event: MouseEvent): void {
    if (event.target instanceof HTMLDivElement && event.target.classList.contains('modal-overlay')) {
      this.onCloseModal();
    }
  }
}
