import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailService } from '../service-api/email.service';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-page-lease',
  templateUrl: './page-lease.component.html',
  styleUrls: ['./page-lease.component.scss']
})
export class PageLeaseComponent implements OnInit {
  environment=environment;

  leaseDrones:Map<string, {
    title: string,
    image: string,
    payloads: { name: string, checked: boolean }[],
    customPayload: string,
    pilotRequired: boolean,
    success_msg: any,
    error_msg: any
  }>=new Map<string, {
    title: string,
    image: string,
    payloads: { name: string, checked: boolean }[],
    pilotRequired: boolean,
    customPayload: string,
    success_msg: any,
    error_msg: any
  }>();


  constructor(private dataService:DataService,private emailService:EmailService){}

  ngOnInit(): void {
    this.loadList();
  }

  loadList(){
      this.dataService.leaseList().subscribe((data:any)=>{
        if(data.success){
          data.products.forEach((element: { slug: string, title: string, image:string, productfeature__title: string[] }) => {
            let payloads:{ name: string, checked: boolean }[] =[];
            element.productfeature__title.forEach(element1 => {
              payloads.push({name:element1,checked:false})
            });
            this.leaseDrones.set(element.slug,{title:element.title,image:element.image,payloads:payloads, pilotRequired:false,customPayload:'',success_msg:null,error_msg:null});
          });
        }
      });  
  }

  getQuoteOnClick(activeSlug: string){
    let selectedPayloads:string[] =[];
    if(this.leaseDrones.get(activeSlug)){
      this.leaseDrones.get(activeSlug)!.payloads.forEach(element => {
        if(element.checked){
          selectedPayloads.push(element.name);
        }
      });
    }
    this.emailService.leaseEnquiry(activeSlug,selectedPayloads,this.leaseDrones.get(activeSlug)!.customPayload,this.leaseDrones.get(activeSlug)!.pilotRequired?'yes':'no').subscribe((data:any)=>{
      if(data.success){
          this.leaseDrones.get(activeSlug)!.success_msg = data.msg;
          setTimeout(()=>{
            this.leaseDrones.get(activeSlug)!.success_msg = null;
          },5000);
        }else{
          this.leaseDrones.get(activeSlug)!.error_msg = data.msg;
          setTimeout(()=>{
            this.leaseDrones.get(activeSlug)!.error_msg = null;
          },5000);
        }
      },
      (error: any) => {
        this.leaseDrones.get(activeSlug)!.error_msg = error.error.msg;
        setTimeout(()=>{
          this.leaseDrones.get(activeSlug)!.error_msg = null;
        },5000);
      }    
      );
  }

}
