import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../service-api/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-coe',
  templateUrl: './page-coe.component.html',
  styleUrls: ['./page-coe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageCoeComponent implements OnInit {
  environment=environment;
  
  coe:{
    id: number,
    name: string,
    slug: string,
    address: string,
    logo: string,
    video_file: string,
    image: string,
    secondary_image: string,
    description: {logo:string,data:string}[],
    coespecification__slug: {description:string}[],
  }={id:0,name:'',slug:'',address:'',logo:'',video_file:'',image:'',secondary_image:'',description:[],coespecification__slug:[]};

  constructor(private dataService:DataService,private activeRoute: ActivatedRoute,private router: Router){
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loadCoe(params['slug']);
    });
  }

  loadCoe(slug:string){
    if(slug){
      this.dataService.droneCoeItemDetail(slug).subscribe((data:any)=>{    
        if(data.success){
          this.coe=data.coes;
        }else{
          this.router.navigate(['/404'],{skipLocationChange: true});
        }
      },(error: any)=>{
        this.router.navigate(['/404'],{skipLocationChange: true});
      });
    }
  }
  
}
